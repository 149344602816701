import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import QuickViewDivider from './QuickViewDivider';

function QuickViewBox({ title, tag, gap, children }) {
  return (
    <Stack
      gap={gap}
      sx={{
        borderRadius: '8px',
        border: '1px solid',
        borderColor: 'greyColors.grey100',
        backgroundColor: 'white',
        padding: '24px',
        color: 'greyColors.grey300'
      }}>
      <Stack direction="row" gap="8px">
        <Typography variant="h4">{title}</Typography>
        {tag && (
          <React.Fragment>
            <QuickViewDivider orientation="vertical" />
            <Typography variant="paragraph">{tag}</Typography>
          </React.Fragment>
        )}
      </Stack>
      {children}
    </Stack>
  );
}

QuickViewBox.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  gap: PropTypes.string,
  tag: PropTypes.string
};

QuickViewBox.defaultProps = {
  gap: '24px'
};

export default QuickViewBox;
