import { loadExplore } from 'actions/explore/load-explore-action';
import { loadFolderByIdAction } from 'actions/folders/load-folder-by-id-action';
import { getReactState } from 'actions/get-react-state-action';
import { increaseUserSubmissionsCountInState } from 'actions/users/add-user-data-action';
import { loadUserAction } from 'actions/users/load-user-action';
import { EXPLORE_PREFIX } from 'constants/app-routes';
import { SHARED_WITH_ME_FOLDER_ID } from 'constants/folders';
import { DISCOVERY_TYPES, USER_EVENTS } from 'constants/userEvents';
import { dispatch as oldDispatch } from 'hooks/AppStateProvider';
import { clearExploreTableUIState } from 'reducer/explore-table-ui';
import { getAllFilters } from 'selectors/companyLists';
import {
  getExploreTableSelectedCompanies,
  getExploreTableUICheckedCompaniesByListId
} from 'selectors/explore-table-ui';
import { getUserHasRunsLeft } from 'selectors/user';
import { formatFilters } from 'utils/companyList-utils';
import { isEmpty } from 'utils/objects-utils';

import { sendExploreEvent, sendUserEvent } from '../users/send-user-event-action';
import {
  monitorDiscoveryProgressAction,
  monitorDiscoveryStatusesAction
} from './monitor-discovery-progress-action';

export function makeNewDiscoveryAction(
  { company_list_id, filters, trigger_service, user_search_term, url },
  onHandleSearchId
) {
  return (dispatch, getState, apiClient) => {
    const oldState = getReactState();
    const reduxState = getState();
    const hasRunsLeft = getUserHasRunsLeft(oldState);
    if (!hasRunsLeft) {
      dispatch(clearExploreTableUIState({ listId: company_list_id }));
      return;
    }
    oldDispatch(increaseUserSubmissionsCountInState());

    const companyMetaIds = getExploreTableSelectedCompanies(reduxState, company_list_id).map(
      (suggestion) => suggestion.companyMetaId
    );
    dispatch(clearExploreTableUIState({ listId: company_list_id }));

    const payload = {
      company_list_id,
      company_meta_ids: companyMetaIds.length > 0 ? companyMetaIds : null,
      trigger_service,
      user_search_term: user_search_term ? user_search_term : null,
      url
    };
    if (filters) {
      const allFilters = getAllFilters(oldState);
      payload.filters = formatFilters(filters, allFilters);
    }

    apiClient.discoveries
      .submitDiscovery(payload)
      .then((res) => {
        dispatch(loadExplore(company_list_id, null, false));
        if (res.ok && res.data) {
          const { record_id: recordId } = res.data;
          onHandleSearchId?.(recordId);
          dispatch(monitorDiscoveryProgressAction(recordId, company_list_id));
          dispatch(monitorDiscoveryStatusesAction(recordId));
          const params = {};
          let discoveryType = null;
          if (url) {
            discoveryType = DISCOVERY_TYPES.URL;
            params.url = url;
          } else if (user_search_term) {
            discoveryType = DISCOVERY_TYPES.QUERY;
            params.query = user_search_term;
          }
          oldDispatch(
            sendExploreEvent(
              USER_EVENTS.DISCOVERY_SUBMITTED,
              company_list_id,
              `Submitted discovery of type ${discoveryType}${
                user_search_term ? ` with query "${user_search_term}"` : ''
              }`,
              {
                listId: company_list_id,
                recordId,
                trigger_service,
                user_search_term,
                url,
                discoveryType,
                params
              }
            )
          );
        }
      })
      .catch(() => {
        oldDispatch(loadUserAction());
      });
  };
}

export function makeNewDryDiscoveryAction(
  name,
  navigate,
  currentListId,
  filteredCompanies,
  folderId
) {
  return (dispatch, getState, apiClient) => {
    const reduxState = getState();
    const actualFolderId = folderId && folderId !== SHARED_WITH_ME_FOLDER_ID ? folderId : null;
    let company_list_meta_ids = null;
    if (currentListId) {
      const selectedComapnies = getExploreTableUICheckedCompaniesByListId(
        reduxState,
        currentListId
      );
      if (isEmpty(selectedComapnies)) {
        company_list_meta_ids = filteredCompanies;
      } else {
        company_list_meta_ids = Object.keys(selectedComapnies);
      }
    }
    dispatch(clearExploreTableUIState({ listId: currentListId }));
    return new Promise((resolve) => {
      apiClient.companiesLists
        .submitList({
          name,
          company_list_meta_ids,
          original_list_id: currentListId,
          folder_id: actualFolderId
        })
        .then((res) => {
          oldDispatch(sendUserEvent(USER_EVENTS.LIST_SUBMITTED, { name }));
          const { company_list_id } = res.data;
          apiClient.discoveries
            .submitDiscovery({ company_list_id, trigger_service: false })
            .then((res2) => {
              if (res2.ok && res2.data) {
                const { record_id: recordId } = res2.data;
                oldDispatch(
                  sendUserEvent(USER_EVENTS.DISCOVERY_SUBMITTED, {
                    listId: company_list_id,
                    recordId,
                    trigger_service: false
                  })
                );
                resolve(company_list_id);
                if (navigate) {
                  navigate(`${EXPLORE_PREFIX}/${company_list_id}`);
                }
              }
            });
          if (actualFolderId) {
            dispatch(loadFolderByIdAction(actualFolderId));
          }
        })
        .catch(() => {
          resolve(null);
        });
    });
  };
}
