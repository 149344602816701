import { Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import IconButtonComponent from 'components/IconButtonComponent';
import CustomAnalyticsFeedMessageComponent from 'components/discovery/CustomAnalyticsFeedMessageComponent';
import PromptDisplayComponent from 'components/discovery/PromptDisplayComponent';
import { CUSTOM_ANALYTICS_PLACEMENT } from 'constants/custom-analytics';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import DeleteIcon from 'icons/DeleteIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getDeepDiveDynamicCardDataById } from 'selectors/deep-dive';
import { getUserIsDev } from 'selectors/user';

import DeleteDynamicCardDialog from './DeleteDynamicCardDialog';

function DeepDiveDynamicCardComponent({ deepDiveId, cardId }) {
  const [isHover, setIsHover] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const cardData = useSelector((state) =>
    getDeepDiveDynamicCardDataById(state, deepDiveId, cardId)
  );
  const isDev = useOldStateSelector(getUserIsDev);

  if (!cardData) return null;
  return (
    <Card
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      sx={{
        padding: { xs: '16px', md: '32px' },
        borderRadius: '4px',
        boxShadow: '0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)'
      }}>
      <CardHeader
        title={
          <Typography
            variant="h3"
            textAlign="left"
            color={'greyColors.grey300'}
            sx={{
              borderBottom: '4px solid',
              width: 'fit-content',
              paddingBottom: '8px'
            }}>
            {cardData.name}
          </Typography>
        }
        action={
          <IconButtonComponent
            tag="delete"
            sx={{
              padding: 0,
              display: isHover ? 'block' : 'none'
            }}
            onClick={() => setOpenDeleteDialog(true)}>
            <DeleteIcon />
          </IconButtonComponent>
        }
      />
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Stack direction="row" alignItems="center" gap="8px">
          <Typography variant="paragraphBold">Question:</Typography>
          <PromptDisplayComponent
            companyMetaId={cardData.company_meta_id}
            prompt={cardData}
            mode="plain"
            deepDiveId={deepDiveId}
          />
        </Stack>
        <CustomAnalyticsFeedMessageComponent
          companyMetaId={cardData.company_meta_id}
          body={cardData.answer}
          followUpQuestions={cardData.follow_up_questions}
          sources={cardData.sources}
          reasoning={cardData.reasoning}
          answerType={cardData.answer_type}
          responseType={cardData.responseType}
          placement={CUSTOM_ANALYTICS_PLACEMENT.DEEP_DIVE}
          toolCallHistory={isDev && cardData.tool_call_history}
        />
      </CardContent>
      <DeleteDynamicCardDialog
        open={openDeleteDialog}
        deepDiveId={deepDiveId}
        cardId={cardId}
        onClose={() => setOpenDeleteDialog(false)}
      />
    </Card>
  );
}

DeepDiveDynamicCardComponent.propTypes = {
  deepDiveId: PropTypes.number,
  cardId: PropTypes.string
};

export default DeepDiveDynamicCardComponent;
