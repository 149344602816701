import { updateRecentlyFinishedRun } from 'actions/update-recently-finished-run';
import { ACTIONS } from 'constants/actions';
import { updateExploreListMetaRecord } from 'reducer/explore-slice';

export function updateRunProgress(runId, progress) {
  return { type: ACTIONS.UPDATE_RUN_PROGRESS, runId, progress };
}

export function monitorResearchProgressAction(recordId, listId, companyMetaId, reduxDispatch) {
  return (_state, dispatch, apiClient) => {
    let tickerId = null;
    const ticker = () => {
      apiClient.runs.fetchRunProgress(recordId).then((res) => {
        if (!res.ok) {
          clearInterval(tickerId);
          return;
        }
        if (res.ok) {
          const { progress } = res.data;
          dispatch(updateRunProgress(recordId, progress));
          if (progress >= 100) {
            clearInterval(tickerId);
            dispatch(updateRecentlyFinishedRun(recordId, 'runs'));
            if (listId && companyMetaId && reduxDispatch) {
              reduxDispatch(
                updateExploreListMetaRecord({
                  listId,
                  recordId,
                  metaId: companyMetaId,
                  recordReady: true
                })
              );
            }
          }
        }
      });
    };
    tickerId = setInterval(ticker, 15000);
    ticker();
  };
}
function updateRunStatuses(runId, statuses) {
  return { type: ACTIONS.UPDATE_RUN_STATUSES, runId, statuses };
}

export function monitorResearchStatusesAction(recordId) {
  return (_state, dispatch, apiClient) => {
    let tickerId = null;
    const ticker = () => {
      apiClient.runs.getRunStatuses(recordId).then((res) => {
        if (!res.ok) {
          clearInterval(tickerId);
          return;
        }
        if (res.ok) {
          const { status, statuses } = res.data;
          if (status !== 'WAITING') {
            clearInterval(tickerId);
            return;
          }

          dispatch(updateRunStatuses(recordId, statuses));
        }
      });
    };
    tickerId = setInterval(ticker, 20000);
    ticker();
  };
}
