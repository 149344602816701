import { loadAllOrganizations } from './load-all-organizations-action';

export function updateOrganizationMaxViewersAction(organizationId, maxViewers) {
  return (_state, dispatch, apiClient) => {
    apiClient.organizations
      .updateOrganizationMaxViewers(organizationId, { max_viewers: maxViewers })
      .then((res) => {
        if (res.ok) {
          dispatch(loadAllOrganizations());
        }
      });
  };
}
