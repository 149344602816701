import { Box, FormControl, Input, MenuItem, Select, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { appTheme } from 'theme';

function SelectWithSearchComponent({
  options,
  disabled,
  value,
  searchPlaceHolder,
  onChange,
  variant,
  sx
}) {
  const selectRef = useRef();

  const [optionsFilterInput, setOptionsFilterInput] = useState('');
  const [minWidth, setMinWidth] = useState(100);
  const [minHeight, setMinHeight] = useState(100);

  const onValueChange = (event) => {
    setOptionsFilterInput('');
    onChange(event);
  };
  const filteredOptions = useMemo(
    () =>
      Object.values(options)
        .filter((option) => option.name)
        .map((option) => ({
          ...option,
          shown: (typeof option.name === 'object' ? option.name[0] : option.name)
            .toLowerCase()
            .includes(optionsFilterInput.toLowerCase())
        })),
    [options, optionsFilterInput]
  );

  useEffect(() => {
    if (!selectRef.current) return;

    const menuRoot = selectRef.current.closest('.MuiPaper-root');
    setMinWidth((prevMinWidth) => {
      return prevMinWidth === 100 ? selectRef.current.parentNode.clientWidth : prevMinWidth;
    });
    setMinHeight((prevMinHeight) => {
      return prevMinHeight === 100 ? menuRoot?.clientHeight - 16 : prevMinHeight;
    });
  }, [selectRef.current?.parentNode?.clientWidth]);

  return (
    <FormControl variant={variant} disabled={disabled}>
      <Select
        labelId="column-select-label"
        id="column-select"
        value={value}
        onChange={onValueChange}
        renderValue={(selected) => (
          <Typography variant="paragraph">
            {options.find((opt) => opt.id === selected).name}
          </Typography>
        )}
        sx={{
          ...appTheme.typography.paragraph,
          ...sx
        }}
        MenuProps={{
          sx: {
            '.MuiPaper-root': {
              minWidth: `${minWidth}px !important`,
              minHeight: `${minHeight}px !important`
            }
          }
        }}
        onKeyDownCapture={(event) => event.stopPropagation()}>
        <Input
          ref={selectRef}
          autoFocus
          placeholder={searchPlaceHolder}
          value={optionsFilterInput}
          onChange={(event) => setOptionsFilterInput(event.target.value)}
          sx={{
            ...appTheme.typography.paragraph
          }}
        />
        {filteredOptions.map((option) => (
          <MenuItem
            key={option.id}
            value={option.id}
            sx={{
              color: 'greyColors.grey250',
              visibility: option.shown ? 'visible' : 'hidden',
              height: option.shown ? 'auto' : 0,
              padding: option.shown ? 'inheriet' : 0
            }}>
            <Stack gap="8px" direction="row" alignItems="center">
              {option.icon && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '20px',
                    height: '20px'
                  }}>
                  {option.icon}
                </Box>
              )}
              <Typography variant="paragraphMedium">{option.name}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
SelectWithSearchComponent.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string
    })
  ),
  disabled: PropTypes.bool,
  value: PropTypes.string,
  searchPlaceHolder: PropTypes.string,
  onChange: PropTypes.func,
  variant: PropTypes.string,
  sx: PropTypes.object
};

SelectWithSearchComponent.defaultProps = {
  variant: 'standard',
  sx: {}
};

export default SelectWithSearchComponent;
