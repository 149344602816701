import AuthorizedRouter from 'components/routers/AuthorizedRouter';
import PublicRouter from 'components/routers/PublicRouter';
import { useAppState } from 'hooks/state-context';
import React from 'react';
import { getUser, getUserFeatureFlags } from 'selectors/user';

import ArtRouter from './ArtRouter';

function AppRouter() {
  const { state } = useAppState();
  const user = getUser(state);
  const featureFlags = getUserFeatureFlags(state);
  const { location } = window;
  if (user?.id && (featureFlags.includes('art') || location.pathname.endsWith('/art'))) {
    return <ArtRouter />;
  } else if (user?.id) {
    return <AuthorizedRouter />;
  }

  return <PublicRouter />;
}

export default AppRouter;
