import { ELEMENT_HEIGHTS } from 'constants/components';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Bar, BarChart, CartesianGrid, Cell, LabelList, XAxis, YAxis } from 'recharts';
import { appTheme } from 'theme';
import { dateStringToTick, getBarColorByIndex, getLineColorByIndex } from 'utils/charts-utils';

import RechartsBox from '../RechartsBox';
import { BenchmarkAxisTick, CustomizedAxisTick, renderBarChartLabel } from './bar-chart-utils';

/* eslint-disable id-length */
export default function BarChartComponent({
  title,
  labels,
  values,
  color,
  xAxisName,
  yAxisName,
  vertical,
  xIsDate,
  yIsInt,
  showMultiColors,
  colors,
  logoLabels,
  onClicks,
  noXLabel,
  mode
}) {
  const chars_per_label = Math.floor(50 / labels.length);
  const data = useMemo(() => {
    return labels.map((xVal, index) => ({
      label: xVal,
      [showMultiColors ? `value${index}` : 'value']: values[index]
    }));
  }, [labels, values, showMultiColors]);

  const tickFormatter = (tick) => {
    return xIsDate ? dateStringToTick(tick, labels) : tick;
  };

  const determineTick = () => {
    if (logoLabels) {
      return <BenchmarkAxisTick benchmarkLabels={logoLabels} />;
    }
    return chars_per_label < 10 ? <CustomizedAxisTick /> : { textOverflow: 'elipsis' };
  };
  const determineBarSize = () => {
    if (mode === 'overview') {
      return 30;
    }
    if (logoLabels) {
      return 30;
    }
    return null;
  };
  const determineXLabel = () => {
    if (noXLabel) {
      return null;
    }
    return xAxisName ? xAxisName : 'Year';
  };

  const determineBarColor = () => {
    return color || getBarColorByIndex(0);
  };
  return (
    <React.Fragment>
      <RechartsBox
        title={title}
        mode={mode}
        height={mode === 'powerpoint' ? '100%' : ELEMENT_HEIGHTS.NORMAL}>
        <BarChart
          style={{
            fontSize: appTheme.typography.paragraph.fontSize,
            color: appTheme.palette.greyColors.grey100,
            fontFamily: appTheme.typography.fontFamily,
            fontWeight: appTheme.typography.paragraph.fontWeight
          }}
          data={data}
          layout={vertical ? 'vertical' : 'horizontal'}
          margin={{
            top: 20,
            right: 40,
            bottom: 40,
            left: 40
          }}>
          {vertical ? (
            <React.Fragment>
              <XAxis
                type="number"
                name={xAxisName}
                label={{
                  value: xAxisName,
                  position: 'bottom',
                  fill: appTheme.palette.greyColors.grey250
                }}
                tick={determineTick(logoLabels)}
                stroke={appTheme.palette.greyColors.grey250}
                axisLine={false}
                tickLine={false}
              />
              <YAxis hide={true} type="category" dataKey="label" />
              <CartesianGrid horizontal={false} stroke={appTheme.palette.greyColors.grey50} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <XAxis
                dataKey="label"
                padding={{ left: 20, right: 20 }}
                name={xAxisName}
                tickFormatter={tickFormatter}
                tick={determineTick(logoLabels)}
                label={{
                  value: determineXLabel(),
                  position: 'bottom',
                  fill: appTheme.palette.greyColors.grey250
                }}
                stroke={appTheme.palette.greyColors.grey250}
                axisLine={false}
                tickLine={false}
              />
              <YAxis
                padding={{ top: 40 }}
                allowDecimals={!yIsInt}
                name={yAxisName}
                label={{
                  value: yAxisName,
                  position: 'insideLeft',
                  angle: -90,
                  style: {
                    textAnchor: 'middle'
                  },
                  fill: appTheme.palette.greyColors.grey250
                }}
                stroke={appTheme.palette.greyColors.grey250}
                axisLine={false}
                tickLine={false}
              />
              <CartesianGrid vertical={false} stroke={appTheme.palette.greyColors.grey50} />
            </React.Fragment>
          )}
          {showMultiColors ? (
            data.map((item, index) => (
              <Bar
                key={item.label}
                isAnimationActive={!vertical}
                maxBarSize={vertical ? 24 : null}
                dataKey={`value${index}`}
                fill={getLineColorByIndex(index)}
                radius={2}
                stackId="a"
                barSize={mode === 'overview' ? 30 : null}>
                {vertical && index === 0 && (
                  <LabelList dataKey="label" content={renderBarChartLabel} />
                )}
              </Bar>
            ))
          ) : (
            <Bar
              isAnimationActive={!vertical}
              maxBarSize={vertical ? 24 : null}
              dataKey="value"
              fill={determineBarColor()}
              radius={2}
              barSize={determineBarSize()}>
              {vertical && <LabelList dataKey="label" content={renderBarChartLabel} />}
              {!vertical &&
                colors &&
                data.map((entry) => (
                  <Cell
                    key={`cell-${entry.label}`}
                    fill={colors[entry.label]}
                    onClick={onClicks && onClicks[entry.label]}
                    cursor="pointer"
                  />
                ))}
            </Bar>
          )}
        </BarChart>
      </RechartsBox>
    </React.Fragment>
  );
}

BarChartComponent.propTypes = {
  title: PropTypes.string,
  labels: PropTypes.array,
  values: PropTypes.array,
  xAxisName: PropTypes.string,
  yAxisName: PropTypes.string,
  vertical: PropTypes.bool,
  xIsDate: PropTypes.bool,
  yIsInt: PropTypes.bool,
  logoLabels: PropTypes.array,
  color: PropTypes.string,
  colors: PropTypes.array,
  showMultiColors: PropTypes.bool,
  noXLabel: PropTypes.bool,
  onClicks: PropTypes.array,
  mode: PropTypes.oneOf(['overview', 'card', 'powerpoint']).isRequired
};
