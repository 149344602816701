import BaseClient from './BaseClient';

const URL_PREFIX = 'company_extra_data';

export default class CompanyExtraDataClient extends BaseClient {
  updateCompaniesExtraData(payload) {
    return this.put(`${URL_PREFIX}/${payload.data_type.toLowerCase()}`, payload);
  }

  updateCompaniesExtraDataInCustomAnalyticsColumn(payload) {
    return this.put(`${URL_PREFIX}/custom_analytics`, payload);
  }

  getCompanyListColumnHistory(company_list_id, data_type) {
    return this.get(
      `${URL_PREFIX}/column_history?company_list_id=${company_list_id}&data_type=${data_type}`
    );
  }

  fetchCompaniesExtraData(company_list_id, company_meta_ids, data_type) {
    return this.put(`${URL_PREFIX}`, { company_list_id, company_meta_ids, data_type });
  }
}
