import { TOAST_TYPES } from 'constants/toasts';
import { addSavedQuery } from 'reducer/saved-queries-slice';

import { translateSavedQuery } from './load-saved-queries-action';

export function addSavedQueryAction({
  name,
  parameters,
  description,
  query_type,
  addToast,
  onSaveQuery
}) {
  return (dispatch, _getState, apiClient) => {
    apiClient.saved_queries
      .addSavedQuery({ name, parameters, description, query_type })
      .then((res) => {
        if (res.ok && res.data) {
          dispatch(addSavedQuery({ savedQuery: translateSavedQuery(res.data) }));
          onSaveQuery?.(res.data.id);
          addToast('Saved query', TOAST_TYPES.SUCCESS);
        } else {
          addToast('Failed to save query', TOAST_TYPES.ERROR);
        }
      })
      .catch(() => {
        addToast('Failed to save query', TOAST_TYPES.ERROR);
      });
  };
}
