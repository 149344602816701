import { makeNewDryDiscoveryAction } from 'actions/discoveries/make-new-discovery-action';
import { SHARED_WITH_ME_FOLDER_ID } from 'constants/folders';
import {
  getCompanyListNameByListId,
  getListDomainKnowledgeResourceByListId
} from 'selectors/explore';
import { getCurrentFolderId } from 'selectors/folders';

import { addCompaniesToListAction } from './add-companies-to-list-action';

export function createListFromDomainKnowledgeAction(listId, resourceId, navigate) {
  return async (dispatch, getState) => {
    const state = getState();
    const listName = getCompanyListNameByListId(state, listId);
    const domainKnowledge = getListDomainKnowledgeResourceByListId(state, listId, resourceId);
    const searchTerms = domainKnowledge.value?.message?.answer
      ?.split(',')
      .map((name) => name.trim());

    const newCompanListId = await dispatch(
      makeNewDryDiscoveryAction(`${domainKnowledge.value.title} from ${listName}`, navigate)
    );
    if (newCompanListId) {
      dispatch(addCompaniesToListAction(newCompanListId, searchTerms));
    }
  };
}

export function createListFromSearchTerms(searchTerms, title, navigate) {
  return async (dispatch, getState) => {
    const state = getState();
    const currentFolderId = getCurrentFolderId(state);
    const actualFolderId =
      currentFolderId && currentFolderId !== SHARED_WITH_ME_FOLDER_ID ? currentFolderId : null;
    const newCompanListId = await dispatch(
      makeNewDryDiscoveryAction(title, navigate, null, null, actualFolderId)
    );
    if (newCompanListId) {
      dispatch(addCompaniesToListAction(newCompanListId, searchTerms));
    }
  };
}
