import PropTypes from 'prop-types';
import React from 'react';

function PrivateIcon({ useDefaultColor }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.333 19V17.4444C18.333 16.6193 17.9906 15.828 17.3811 15.2446C16.7716 14.6611 15.945 14.3333 15.083 14.3333H8.58301C7.72105 14.3333 6.8944 14.6611 6.28491 15.2446C5.67542 15.828 5.33301 16.6193 5.33301 17.4444V19M15.083 8.11111C15.083 9.82933 13.6279 11.2222 11.833 11.2222C10.0381 11.2222 8.58301 9.82933 8.58301 8.11111C8.58301 6.39289 10.0381 5 11.833 5C13.6279 5 15.083 6.39289 15.083 8.11111Z"
        stroke={useDefaultColor ? '#0F2345' : 'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PrivateIcon;
PrivateIcon.propTypes = {
  useDefaultColor: PropTypes.bool
};

PrivateIcon.defaultProps = {
  useDefaultColor: false
};
