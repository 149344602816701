/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */
import { Box, ListItemIcon, ListItemText, MenuItem, Switch } from '@mui/material';
import {
  updateCustomAnalyticsExtraDataForCompaniesInListAction,
  updateExtraDataByTypeForCompaniesInListAction
} from 'actions/company_extra_data/update-extra-data-for-companies-in-list-action';
import { editDiscoveryCustomColumnInfoAction } from 'actions/explore/edit-discovery-custom-column-info-action';
import NestedMenuItemComponent from 'components/menu/NestedMenuItem';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import ArrowIcon from 'icons/ArrowIcon';
import PlayIcon from 'icons/PlayIcon';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getExploreFilteredMetaIdsIfListFiltered,
  getExploreTableSelectedCompanies
} from 'selectors/explore-table-ui';
import { getUserIsDev } from 'selectors/user';
import { EXTRA_DATA_COLUMN_TYPE } from 'utils/extra-data-utils';

export default function ExtraDataColumnMenuItem({ currentColumn, listId, hideMenu, handleClose }) {
  const isDev = useOldStateSelector(getUserIsDev);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const allowFillRows =
    currentColumn?.type?.includes('custom_analytics') ||
    (isDev && currentColumn.field === 'revenues');
  const allowRefresh =
    currentColumn?.type?.includes('custom_analytics') ||
    currentColumn?.type === EXTRA_DATA_COLUMN_TYPE ||
    currentColumn.field === 'revenues';

  const allowAutoFill = currentColumn?.type?.includes('custom_analytics');
  const filteredMetaIdsIfFiltered = useSelector((state) =>
    getExploreFilteredMetaIdsIfListFiltered(state, listId)
  );
  const selectedCompanies = useSelector((state) => getExploreTableSelectedCompanies(state, listId));

  const handleSubmenuClose = () => {
    setSubMenuAnchorEl(null);
  };

  const handleRefreshData = (event, recompute) => {
    let selectedCompanyMetaIds = null;
    if (selectedCompanies?.length > 0) {
      selectedCompanyMetaIds = selectedCompanies.map((company) => company.companyMetaId);
    } else if (filteredMetaIdsIfFiltered) {
      selectedCompanyMetaIds = filteredMetaIdsIfFiltered;
    }

    if (currentColumn.type?.includes('custom_analytics')) {
      dispatch(
        updateCustomAnalyticsExtraDataForCompaniesInListAction(
          listId,
          currentColumn.field,
          recompute,
          selectedCompanyMetaIds
        )
      );
    }
    if (currentColumn.type === EXTRA_DATA_COLUMN_TYPE || currentColumn.field === 'revenues') {
      dispatch(
        updateExtraDataByTypeForCompaniesInListAction(
          listId,
          currentColumn.field.toUpperCase(),
          recompute
        )
      );
    }
    hideMenu(event);
    handleSubmenuClose();
    handleClose();
  };

  if (!allowFillRows && !allowRefresh && !allowAutoFill) {
    return null;
  }

  if (!allowFillRows && !allowAutoFill) {
    return (
      <MenuItem onClick={(event) => handleRefreshData(event, true)}>
        <ListItemIcon>
          <PlayIcon />
        </ListItemIcon>
        <ListItemText>Rerun all companies</ListItemText>
      </MenuItem>
    );
  }

  const toggleColumnAutoFill = () => {
    const oldInfo = currentColumn?.extraParams?.info || {};
    const newInfo = { ...oldInfo, auto_fill: !oldInfo.auto_fill };
    dispatch(editDiscoveryCustomColumnInfoAction(listId, currentColumn.field, newInfo));
    if (newInfo.auto_fill) {
      dispatch(updateCustomAnalyticsExtraDataForCompaniesInListAction(listId, currentColumn.field));
    }
  };

  const menuItems = [
    {
      isVisible: allowFillRows,
      text: 'Run Empty Cells',
      onClick: (event) => handleRefreshData(event, false)
    },
    {
      isVisible: allowRefresh,
      text: 'Rerun All Companies',
      onClick: (event) => handleRefreshData(event, true)
    },
    {
      isVisible: allowAutoFill,
      text: `Auto-Fill New Companies`,
      onClick: toggleColumnAutoFill,
      endIcon: (
        <Switch
          size="small"
          checked={currentColumn?.extraParams?.info?.auto_fill}
          onChange={toggleColumnAutoFill}
        />
      )
    }
  ].filter((item) => item.isVisible);

  return (
    <NestedMenuItemComponent
      subMenuItems={menuItems}
      subMenuAnchorEl={subMenuAnchorEl}
      setSubMenuAnchorEl={setSubMenuAnchorEl}
      startIcon={<PlayIcon />}
      endIcon={
        <Box sx={{ transform: 'rotate(90deg)' }}>
          <ArrowIcon />
        </Box>
      }
      text="Run data"
    />
  );
}

ExtraDataColumnMenuItem.propTypes = {
  currentColumn: PropTypes.object,
  listId: PropTypes.number,
  hideMenu: PropTypes.func,
  handleClose: PropTypes.func
};
