import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { addUserData } from 'actions/users/add-user-data-action';
import { getAPIClient, initAPIClient } from 'api/client-api';
import HookErrorDialog from 'components/HookErrorDialog';
import LoaderComponent from 'components/LoaderComponent';
import { dispatch } from 'hooks/AppStateProvider';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { getUser } from 'selectors/user';

import { initPusherClient } from './PusherProvider';
import { useAppState } from './state-context';

const PATH_BEFORE_LOGIN_KEY = 'path_before_login';

function UserProvider({ children }) {
  const { state } = useAppState();
  const stateUser = getUser(state);
  const [errorConfig, setErrorConfig] = useState(null);
  const [mathlabsUser, setMathlabsUser] = useState();
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const showLoader =
    isLoading || (isAuthenticated && !stateUser?.username && mathlabsUser?.notAuthorized !== true);

  const setErrorConfigCallback = useCallback(
    (newErrorConfig) => {
      if (newErrorConfig.status === 402) {
        setErrorConfig(newErrorConfig);
      }
    },
    [setErrorConfig]
  );

  useEffect(() => {
    if (!isAuthenticated || isLoading) return;
    getAccessTokenSilently({
      authorizationParams: {
        // eslint-disable-next-line no-undef
        audience: process.env.REACT_APP_AUTH0_AUDIENCE
      }
    })
      .then(() => {
        initAPIClient(getAccessTokenSilently, setErrorConfigCallback);
        try {
          initPusherClient();
        } catch (error) {
          // eslint-disable-next-line
          console.log('Failed in getting pusher token - continue without pusher', error);
        }
        getAPIClient()
          .users.getUser()
          .then((res) => {
            const pathBeforeLogin = window.localStorage.getItem(PATH_BEFORE_LOGIN_KEY);
            if (pathBeforeLogin) {
              window.localStorage.removeItem(PATH_BEFORE_LOGIN_KEY);
              if (pathBeforeLogin !== '/') {
                history.pushState({}, null, pathBeforeLogin);
              }
            }
            setMathlabsUser(res.ok ? res.data : { notAuthorized: true });
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.log('GET User failed!', error);
            setMathlabsUser({ notAuthorized: true });
          });
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log('failed in getting auth0 token', error);
        setMathlabsUser({ notAuthorized: true });
      });
  }, [getAccessTokenSilently, isAuthenticated, isLoading, setErrorConfigCallback]);
  useEffect(() => {
    if (user && mathlabsUser && user.sub === mathlabsUser?.auth0_id) {
      const { sub, picture } = user;
      dispatch(
        addUserData({ auth0_id: sub, is_verified: user.email_verified, picture, ...mathlabsUser })
      );
    } else if (user) {
      const { email, sub, picture } = user;
      dispatch(addUserData({ id: sub, email, picture }));
    }
  }, [user, mathlabsUser]);
  return (
    <React.Fragment>
      <HookErrorDialog
        open={Boolean(errorConfig) && errorConfig?.status === 402}
        onClose={() => setErrorConfig(null)}
        errorConfig={errorConfig}
      />
      {showLoader ? <LoaderComponent monitorTimeOpen /> : children}
    </React.Fragment>
  );
}

UserProvider.propTypes = {
  children: PropTypes.any,
  setErrorConfig: PropTypes.func
};

export function AuthProvider({ children }) {
  const providerConfig = {
    // eslint-disable-next-line no-undef
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    // eslint-disable-next-line no-undef
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    authorizationParams: {
      redirect_uri: window.location.origin,
      // eslint-disable-next-line no-undef
      audience: process.env.REACT_APP_AUTH0_AUDIENCE
    }
  };
  if (window.location.search.includes('screen_hint=signup')) {
    providerConfig.authorizationParams.screen_hint = 'signup';
  }
  return (
    <Auth0Provider {...providerConfig}>
      <UserProvider>{children}</UserProvider>
    </Auth0Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.any
};
