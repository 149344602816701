import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Link,
  Menu,
  MenuItem,
  Stack,
  Typography
} from '@mui/material';
import { createNewDiscoveryInCustomAnalyticsThreadAction } from 'actions/custom-analytics/create-new-discovery-in-custom-analytics-thread-action';
import { makeNewSearchRunAction } from 'actions/runs/make-new-search-run-action';
import IconButtonComponent from 'components/IconButtonComponent';
import ModalComponent from 'components/modal/ModalComponent';
import { COMPANY_INSIGHTS_PREFIX } from 'constants/app-routes';
import { OVERVIEW_TAB } from 'constants/company-insights';
import { DISCOVERY_TYPES } from 'constants/discovery';
import { dispatch } from 'hooks/AppStateProvider';
import { useAppState } from 'hooks/state-context';
import CloseIcon from 'icons/CloseIcon';
import CrunchbaseIcon from 'icons/CrunchbaseIcon';
import DeepDiveIcon from 'icons/DeepDiveIcon';
import DeleteIcon from 'icons/DeleteIcon';
import DownIcon from 'icons/DownIcon';
import ExportIcon from 'icons/ExportIcon';
import LinkedinIcon from 'icons/LinkedinIcon';
import MenuIcon from 'icons/MenuIcon';
import SearchForPeersIcon from 'icons/SearchForPeersIcon';
import SourceIcon from 'icons/SourceIcon';
import UpIcon from 'icons/UpIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getUserFeatureFlags } from 'selectors/user';
import { appTheme } from 'theme';
import { COLUMN_TYPES } from 'utils/explore-table-columns';

const iconButtonStyle = {
  height: '32px',
  width: '32px',
  color: 'greyColors.grey300',
  padding: 0
};
const upDownButtonStyle = {
  ...iconButtonStyle,
  width: '28px'
};

/* eslint-disable max-lines */
function QuickViewTitle({
  listId,
  value,
  onClose,
  getFieldValue,
  onUp,
  onDown,
  toPDF,
  exporting,
  setExporting
}) {
  const { name, logoUrl, recordId, recordReady } = value;
  const reduxDispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [submittedPeers, setSubmittedPeers] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const open = Boolean(anchorEl);
  const { state } = useAppState();
  const canQuickView = (getUserFeatureFlags(state) || []).includes('quick_view');
  const determineDeepDiveText = () => {
    if (recordId) {
      if (recordReady) {
        return 'See Deep Dive';
      }
      return 'Running Deep Dive';
    }
    return 'Run Deep Dive';
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onDeepDive = () => {
    if (recordId && !recordReady) return;
    if (recordId) {
      if (recordReady) {
        window.open(`${COMPANY_INSIGHTS_PREFIX}/${recordId}/${OVERVIEW_TAB.path}`);
        return;
      }
      return;
    }
    dispatch(makeNewSearchRunAction(value.suggestion, null, reduxDispatch, listId));
  };
  const onDelete = () => {
    value.onDelete?.(value.suggestion.companyListMetaId);
    setDeleteOpen(false);
  };
  const exportPDF = toPDF
    ? () => {
        setExporting(true);
        toPDF().then(() => setExporting(false));
      }
    : null;
  const onSubmitPeers = () => {
    setSubmittedPeers(true);
    reduxDispatch(
      createNewDiscoveryInCustomAnalyticsThreadAction(
        listId,
        DISCOVERY_TYPES.PEERS,
        null,
        null,
        null,
        value.companyListMetaId
      )
    );
  };
  return (
    <Stack
      id="alert-dialog-title"
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        borderBottom: '1px solid',
        padding: '24px',
        justifyContent: 'space-between',
        borderColor: 'greyColors.grey100'
      }}>
      <Stack gap="16px" direction="row">
        {logoUrl && <Avatar src={logoUrl} sx={{ width: 50, height: 50 }} variant="rounded" />}
        <Stack gap="4px">
          <Typography variant="h3" color="greyColors.grey300">
            {name}
          </Typography>
          <Typography variant="paragraph" color="greyColors.grey250">
            {getFieldValue(COLUMN_TYPES.SUMMARY)}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" gap="8px" sx={{ height: '32px' }}>
        <ButtonGroup>
          <Button sx={upDownButtonStyle} disabled={!onDown} onClick={onDown}>
            <DownIcon />
          </Button>
          <Button sx={upDownButtonStyle} disable={!onUp} onClick={onUp}>
            <UpIcon />
          </Button>
        </ButtonGroup>
        <Button
          variant="outlined"
          sx={{ height: '32px', minWidth: 'fit-content', paddingLeft: '4px', paddingRight: '8px' }}
          onClick={onDeepDive}>
          <Stack direction="row" gap="4px" alignItems="center" justifyContent="center">
            {recordId && !recordReady ? (
              <CircularProgress color="primary" size="20px" />
            ) : (
              <DeepDiveIcon variant="secondary" color={appTheme.palette.primary.primary50} />
            )}
            <Typography variant="paragraphSemiBold">{determineDeepDiveText()}</Typography>
          </Stack>
        </Button>
        <IconButtonComponent sx={iconButtonStyle} tag="Delete" onClick={() => setDeleteOpen(true)}>
          <DeleteIcon />
        </IconButtonComponent>
        <IconButtonComponent sx={iconButtonStyle} tag="More" onClick={handleMenu}>
          <MenuIcon />
        </IconButtonComponent>
        <Box sx={{ height: '32px', width: '1px', backgroundColor: 'greyColors.grey100' }} />
        <IconButtonComponent sx={iconButtonStyle} tag="Close" onClick={onClose}>
          <CloseIcon />
        </IconButtonComponent>
      </Stack>
      <Menu
        id="bulk-record-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        textAlign="left"
        sx={{
          '&.MuiPaper-root': {
            backgroundColor: 'primary.white'
          }
        }}>
        {canQuickView && exportPDF && (
          <React.Fragment>
            <MenuItem textAlign="left" color="secondary.secondary1">
              <Stack
                direction="row"
                alignItems="center"
                gap="4px"
                onClick={exportPDF}
                sx={{ marginLeft: '-2px' }}>
                <ExportIcon />
                {exporting ? 'Exporting...' : 'Export'}
              </Stack>
            </MenuItem>
            <Box
              sx={{
                height: '1px',
                width: '100%',
                borderTop: '1px solid',
                borderColor: 'greyColors.grey100',
                marginY: '1px'
              }}></Box>
          </React.Fragment>
        )}
        {canQuickView && (
          <React.Fragment>
            <MenuItem textAlign="left" color="secondary.secondary1">
              <Stack
                direction="row"
                alignItems="center"
                gap="4px"
                onClick={onSubmitPeers}
                sx={{ marginLeft: '-2px' }}>
                <SearchForPeersIcon />
                {submittedPeers ? 'Searching for peers...' : 'Search for peers'}
              </Stack>
            </MenuItem>
            <Box
              sx={{
                height: '1px',
                width: '100%',
                borderTop: '1px solid',
                borderColor: 'greyColors.grey100',
                marginY: '1px'
              }}></Box>
          </React.Fragment>
        )}
        {value.website && (
          <MenuItem textAlign="left" color="secondary.secondary1">
            <Link href={value.website} underline="none" target="_blank" rel="noopener">
              <Stack direction="row" alignItems="center" gap="8px">
                <SourceIcon />
                Go to website
              </Stack>
            </Link>
          </MenuItem>
        )}
        {value.linkedinUrl && (
          <MenuItem textAlign="left" color="secondary.secondary1">
            <Link href={value.linkedinUrl} underline="none" target="_blank" rel="noopener">
              <Stack direction="row" alignItems="center" gap="8px">
                <LinkedinIcon />
                Go to Linkedin
              </Stack>
            </Link>
          </MenuItem>
        )}
        {value.cbPermalink && (
          <MenuItem textAlign="left" color="secondary.secondary1">
            <Link
              href={`https://www.crunchbase.com/organization/${value.cbPermalink}`}
              underline="none"
              target="_blank"
              rel="noopener">
              <Stack direction="row" alignItems="center" gap="8px">
                <CrunchbaseIcon />
                Go to Crunchbase
              </Stack>
            </Link>
          </MenuItem>
        )}
      </Menu>
      <ModalComponent
        title="Delete company from list"
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        maxWidth={500}
        bottomBar={
          <Stack
            direction="row"
            gap="8px"
            alignItems="center"
            justifyContent="space-between"
            padding="0 24px"
            sx={{
              width: '100%'
            }}>
            <Button onClick={() => setDeleteOpen(false)}>Cancel</Button>
            <Button variant="contained" onClick={onDelete}>
              Delete
            </Button>
          </Stack>
        }>
        <Stack padding="24px">
          <Typography variant="paragraph">
            Are you sure you want to delete <strong>{value.name}</strong> from the list?
          </Typography>
        </Stack>
      </ModalComponent>
    </Stack>
  );
}

QuickViewTitle.propTypes = {
  value: PropTypes.object,
  onClose: PropTypes.func,
  getFieldValue: PropTypes.func,
  onUp: PropTypes.func,
  onDown: PropTypes.func,
  listId: PropTypes.string,
  toPDF: PropTypes.func,
  exporting: PropTypes.bool,
  setExporting: PropTypes.func
};

export default QuickViewTitle;
