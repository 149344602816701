import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';
import { countryObjFromCountry } from 'utils/location-utils';

const geoUrl = 'https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json';

const determineFillAndStroke = (isTarget, isMany) => {
  if (isMany) {
    return isTarget ? { fill: 'red', stroke: 'none' } : { fill: 'grey', stroke: 'none' };
  }
  return isTarget ? { fill: 'none', stroke: 'red' } : { fill: 'none', stroke: 'grey' };
};

function CountryMap({ country, countryData }) {
  if (!countryData) return null;
  const countryObj = countryObjFromCountry(country);
  const countryCounts = countryData?.reduce((acc, item) => {
    if (acc[item]) {
      acc[item]++;
    } else {
      acc[item] = 1;
    }
    return acc;
  }, {});
  const mapData = Object.keys(countryCounts || [])
    .map((name) => {
      const obj = countryObjFromCountry(name);
      return obj
        ? {
            ...obj,
            number: countryCounts[name]
          }
        : null;
    })
    .filter((item) => item)
    .reduce((acc, item) => {
      const existing = acc.find((item2) => item2.alpha2 === item.alpha2);
      if (existing) {
        existing.number += item.number;
      } else {
        acc.push(item);
      }
      return acc;
    }, []);
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <ComposableMap
        height={400}
        width={740}
        projection="geoMercator"
        projectionConfig={{
          rotate: [0, 0, 0],
          center: [10, 25],
          scale: 120
        }}>
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => <Geography key={geo.rsmKey} geography={geo} fill="#DDD" />)
          }
        </Geographies>
        {mapData.map(({ alpha2, longitude, latitude, number }) => {
          const isTarget = countryObj && countryObj.alpha2 === alpha2;
          const isMany = number > 5;
          const scale = isTarget || isMany ? 1.6 : 1;
          const styling = determineFillAndStroke(isTarget, isMany);
          return (
            <Marker key={alpha2} coordinates={[longitude, latitude]}>
              <g
                fill={styling.fill}
                stroke={styling.stroke}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                transform={`translate(${-1 * 12 * scale}, ${
                  -2 * 12 * scale
                }) scale(${scale}, ${scale})`}>
                {isMany ? null : <circle cx="12" cy="10" r="3" />}
                <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
              </g>
              {isMany && (
                <text
                  textAnchor="middle"
                  y={-15}
                  style={{ fill: 'white', fontSize: number > 99 ? '12' : '18' }}>
                  {number}
                </text>
              )}
            </Marker>
          );
        })}
      </ComposableMap>
    </Box>
  );
}

CountryMap.propTypes = {
  country: PropTypes.string,
  countryData: PropTypes.array
};

export default CountryMap;
