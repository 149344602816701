import { updateExploreCompanyIsCheckedByCompanyListMetaId } from 'actions/explore-table-ui/update-discovery-ui-state-by-company-meta-id';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getExploreFilteredCompanies,
  getExploreTableUICheckedCompaniesByListId
} from 'selectors/explore-table-ui';

import RenderBulkReportHeader from './RenderBulkReportHeader';

export default function RenderBulkReportHeaderConnector({ colDef }) {
  const listId = colDef.extraParams?.listId;
  const dispatch = useDispatch();
  const discoveryCompaniesUi = useSelector((state) =>
    getExploreTableUICheckedCompaniesByListId(state, listId)
  );

  const filteredCompanies = useSelector((reduxState) =>
    getExploreFilteredCompanies(reduxState, listId)
  );

  const isChecked = useMemo(() => {
    if (!listId || !filteredCompanies) return false;
    const numSelectedOutOfFiltered = filteredCompanies.filter((companyListMetaId) =>
      Boolean(discoveryCompaniesUi?.[companyListMetaId])
    ).length;
    if (
      numSelectedOutOfFiltered !== 0 &&
      numSelectedOutOfFiltered === filteredCompanies.filter((clmi) => clmi).length
    ) {
      return true;
    }
    return false;
  }, [listId, discoveryCompaniesUi, filteredCompanies]);

  if (!listId) {
    return <RenderBulkReportHeader colDef={colDef} />;
  }

  const allowCheckbox = colDef.extraParams?.allowCheckbox;

  const showCheckBox = discoveryCompaniesUi && Object.keys(discoveryCompaniesUi).length > 0;

  const onCheck = (checked) =>
    filteredCompanies
      .filter((companyListMetaId) => companyListMetaId)
      .forEach((companyListMetaId) =>
        dispatch(
          updateExploreCompanyIsCheckedByCompanyListMetaId(listId, companyListMetaId, checked)
        )
      );

  return (
    <RenderBulkReportHeader
      colDef={colDef}
      showCheckBox={showCheckBox}
      allowCheckbox={allowCheckbox}
      isChecked={isChecked}
      onCheck={onCheck}
    />
  );
}

RenderBulkReportHeaderConnector.propTypes = {
  colDef: PropTypes.object
};
