import React from 'react';

export default function DownIcon() {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.96484 4.58341V12.9167M8.96484 12.9167L4.96484 8.91675M8.96484 12.9167L12.9648 8.91675"
        stroke="#2A2A2A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
