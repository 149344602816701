import React from 'react';

export default function PlayIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.35382 8.33632C9.51057 8.24757 9.70295 8.25 9.85742 8.34268L15.5717 11.7712C15.7223 11.8616 15.8145 12.0244 15.8145 12.2C15.8145 12.3756 15.7223 12.5384 15.5717 12.6287L9.85742 16.0573C9.70295 16.15 9.51057 16.1524 9.35382 16.0637C9.19706 15.9749 9.10017 15.8087 9.10017 15.6286V8.77142C9.10017 8.59129 9.19706 8.42508 9.35382 8.33632ZM10.1002 9.65452V14.7455L14.3426 12.2L10.1002 9.65452Z"
        fill="currentColor"></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 5.19998H7.39995C6.18492 5.19998 5.19995 6.18496 5.19995 7.39998V16.5C5.19995 17.715 6.18492 18.7 7.39995 18.7H16.5C17.715 18.7 18.7 17.715 18.7 16.5V7.39998C18.7 6.18496 17.715 5.19998 16.5 5.19998ZM7.39995 4.19998C5.63264 4.19998 4.19995 5.63267 4.19995 7.39998V16.5C4.19995 18.2673 5.63264 19.7 7.39995 19.7H16.5C18.2673 19.7 19.7 18.2673 19.7 16.5V7.39998C19.7 5.63267 18.2673 4.19998 16.5 4.19998H7.39995Z"
        fill="currentColor"></path>
    </svg>
  );
}
