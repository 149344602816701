import { createSelector } from '@reduxjs/toolkit';

import {
  getCompanyListHistoryByListId,
  getDiscoveriesIdsByListId,
  getExplore,
  getExploreListByListId
} from './explore';

export const getAllDiscoveries = (state) => getExplore(state).discoveryRecords;
export const getDiscoveryByDiscoveryId = (state, discoveryId) =>
  getAllDiscoveries(state)?.[discoveryId];

// receive state, listId
export const getDiscoveriesByListId = createSelector(
  getAllDiscoveries,
  getDiscoveriesIdsByListId,
  (discoveries, discoveryIds) =>
    discoveryIds?.reduce((prev, discoveryId) => {
      prev[discoveryId] = discoveries[discoveryId];
      return prev;
    }, {})
);

export const getLastDiscoveryIdByExploreList = (list) => list?.discoveryIds?.slice(-1)?.[0];
export const getLastDiscoveryIdByListId = (state, listId) => {
  const exploreList = getExploreListByListId(state, listId);
  if (!exploreList) return null;
  return getLastDiscoveryIdByExploreList(exploreList);
};

export const getLastDiscoveryByListId = (state, listId) => {
  const discoveryId = getLastDiscoveryIdByListId(state, listId);
  return getDiscoveryByDiscoveryId(state, discoveryId);
};

export const getCompanyListIdByDiscoveryId = (state, discoveryId) =>
  getDiscoveryByDiscoveryId(state, discoveryId)?.companyListId;
export const getDiscoveryNameByDiscoveryId = (state, discoveryId) => {
  const listId = getCompanyListIdByDiscoveryId(state, discoveryId);
  return getExploreListByListId(state, listId)?.name;
};
export const getDiscoveryReadyById = (state, discoveryId) =>
  getDiscoveryByDiscoveryId(state, discoveryId)?.ready;
export const getDiscoveryTimestampById = (state, discoveryId) =>
  getDiscoveryByDiscoveryId(state, discoveryId)?.timeSubmitted;
export const getDiscoveryProgressById = (state, discoveryId) =>
  getDiscoveryByDiscoveryId(state, discoveryId)?.progress;
export const getDiscoveryProgressMessageById = (state, discoveryId) =>
  getDiscoveryByDiscoveryId(state, discoveryId)?.progressMessage;
export const getDiscoveryStatusesById = (state, discoveryId) =>
  getDiscoveryByDiscoveryId(state, discoveryId)?.statuses;
export const getDiscoveryNameById = (state, discoveryId) =>
  getDiscoveryByDiscoveryId(state, discoveryId)?.name;

export const getDiscoveryFailedById = (state, discoveryId) => {
  const statuses = getDiscoveryStatusesById(state, discoveryId);
  if (!statuses) {
    return null;
  }
  const failed = Object.keys(statuses).filter(
    (service) => statuses[service] === 'FAILED' || statuses[service] === 'SUBMISSION_FAILED'
  );
  return failed.length > 0;
};

export const getAllCurrentlyRunningDiscoveries = createSelector(
  getAllDiscoveries,
  (state) => state,
  (discoveries, state) => {
    return Object.keys(discoveries).reduce((acc, id) => {
      if (!getDiscoveryReadyById(state, id) && getDiscoveryProgressById(state, id) !== 100) {
        acc[id] = discoveries[id];
      }
      return acc;
    }, {});
  }
);
export const getCurrentlyRunningDiscoveriesByListId = createSelector(
  getDiscoveriesByListId,
  getAllDiscoveries,
  (listDiscoveries, discoveries) => {
    if (!listDiscoveries) return null;
    return Object.keys(listDiscoveries)?.reduce((acc, id) => {
      if (!discoveries[id]?.ready && discoveries[id]?.progress !== 100) {
        acc[id] = listDiscoveries[id];
      }
      return acc;
    }, {});
  }
);
export const getCompanyListHistoryByDiscoveryId = (state, discoveryId) => {
  const companyListId = getCompanyListIdByDiscoveryId(state, discoveryId);
  return getCompanyListHistoryByListId(state, companyListId);
};

export const getCurrentDiscoveryCompanyListId = (state) => state.currentDiscoveryCompanyListId;
