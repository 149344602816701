import { Avatar, CardMedia, Chip, Divider, Stack, Tooltip, Typography } from '@mui/material';
import PersonCardDialog from 'components/card/people/PersonCardDialog';
import SmallCompanyLogoComponent from 'components/card/people/SmallCompanyLogoComponent';
import RerunStack from 'components/tile/RerunStack';
import RunningSearchAction from 'components/tile/person/RunningSearchAction';
import { RESEARCH_TYPES } from 'constants/researches';
import { RESOURCE_TYPES } from 'constants/resources';
import { TILE_TYPE } from 'constants/tiles';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentFolderId } from 'selectors/folders';
import {
  getPersonCardById,
  getPersonCurrentCompanyLogoUrlById,
  getPersonCurrentCompanyNameById,
  getPersonCurrentRoleById,
  getPersonImageUrlById,
  getPersonIsMineById,
  getPersonNameById,
  getPersonReadyById,
  getPersonRecordIdById
} from 'selectors/persons';
import { getUserHasRunsLeft, getUserId } from 'selectors/user';

import HomePageTile from './HomePageTile';

export default function HomePagePersonTile({ id }) {
  const [openPersonDialog, setOpenPersonDialog] = useState(false);
  const [isOnHover, setIsOnHover] = useState(false);

  const name = useOldStateSelector((state) => getPersonNameById(state, id));
  const userId = useOldStateSelector(getUserId);
  const isMine = useOldStateSelector((state) => getPersonIsMineById(state, id, userId));
  const imageUrl = useOldStateSelector((state) => getPersonImageUrlById(state, id));
  const curretRole = useOldStateSelector((state) => getPersonCurrentRoleById(state, id));
  const currentCompanyName = useOldStateSelector((state) =>
    getPersonCurrentCompanyNameById(state, id)
  );
  const currentCompanyLogo = useOldStateSelector((state) =>
    getPersonCurrentCompanyLogoUrlById(state, id)
  );
  const folderId = useSelector(getCurrentFolderId);
  const recordId = useOldStateSelector((state) => getPersonRecordIdById(state, id));
  const isDisabled = !useOldStateSelector((state) => getPersonReadyById(state, id));
  const additionalData = useOldStateSelector((state) => getPersonCardById(state, id));
  const userHasRunsLeft = useOldStateSelector(getUserHasRunsLeft);
  const schools = additionalData?.notable_schools?.slice(0, 4) || [];
  const companies = additionalData?.notable_companies?.slice(0, 4) || [];
  const notables = [...companies, ...schools];
  const proficiencies = additionalData?.proficiencies_data?.dominant_functions?.slice(0, 2);

  const handleOpenPersonDialog = (event) => {
    event.stopPropagation();
    setOpenPersonDialog(true);
  };

  const closePersonDialog = (event) => {
    event?.stopPropagation();
    setOpenPersonDialog(false);
  };

  return (
    <React.Fragment>
      <HomePageTile
        Icon={RESEARCH_TYPES.LEADERSHIP.Icon}
        showDelete={isOnHover && !isDisabled}
        recordId={recordId}
        searchId={id}
        recordType={TILE_TYPE.PERSON}
        resourceType={RESOURCE_TYPES.PERSON}
        researchName={name}
        folderId={folderId}
        isMine={isMine}
        isOnHover={isOnHover}
        showMove
        showRerun={userHasRunsLeft}
        setIsOnHover={setIsOnHover}
        onClick={handleOpenPersonDialog}
        content={
          <Stack
            direction="column"
            gap="4px"
            padding="16px"
            sx={{ height: '100%', boxSizing: 'border-box' }}>
            <Stack direction="center" alignItems="center" gap="8px">
              <Avatar
                alt={`${name} image`}
                src={imageUrl}
                sx={{
                  width: 48,
                  height: 48,
                  border: '1px solid',
                  borderColor: 'primary.primary50',
                  borderRadius: '50%'
                }}
              />
              <Stack direction="column" alignItems="flex-start" gap="4px" width="calc(100% - 48px)">
                <Typography variant="h4" color="greyColors.grey300" sx={{ textWrap: 'nowrap' }}>
                  {name}
                </Typography>
                <Stack direction="row" alignItems="center" gap="8px" width="100%">
                  <Stack direction="center" alignItems="center" gap="8px" width="100%">
                    {currentCompanyLogo && (
                      <CardMedia
                        sx={{ width: '20px', height: '20px', borderRadius: '2px' }}
                        component="img"
                        image={currentCompanyLogo}
                        alt={'the current company name'}
                      />
                    )}
                    {currentCompanyName && (
                      <Tooltip title={currentCompanyName}>
                        <Typography variant="tiny" color="greyColors.grey300">
                          {currentCompanyName}
                        </Typography>
                      </Tooltip>
                    )}
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ borderColor: 'greyColors.grey100' }}
                    />
                    <Typography
                      variant="tiny"
                      title={curretRole}
                      color="greyColors.grey150"
                      sx={{
                        flex: 1,
                        textWrap: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        textAlign: 'left'
                      }}>
                      {curretRole}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="column" justifyContent="flex-end" gap="16px" flex={1}>
              {notables?.length > 0 && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="12px">
                  <Typography variant="tiny" color="secondary.secondary6">
                    Notable Institutions
                  </Typography>
                  <Stack
                    direction="row"
                    gap="16px"
                    alignItems="center"
                    onClick={(event) => event.stopPropagation()}>
                    {notables.map((school, index) => (
                      <SmallCompanyLogoComponent
                        imageUrl={school.image_url}
                        key={`school-${index}`}
                        tooltipText={school.name}
                        linkUrl={school.linkedin_url}
                        size="16px"
                      />
                    ))}
                  </Stack>
                </Stack>
              )}
              {proficiencies?.length > 0 && (
                <Stack
                  direction="row"
                  gap="16px"
                  alignItems="center"
                  sx={{ alignItems: 'flex-end' }}>
                  {proficiencies.map((proficien) => (
                    <Chip
                      key={proficien.role}
                      label={proficien.role}
                      size="small"
                      sx={{
                        border: proficien.is_current ? '2px solid' : null,
                        borderColor: 'secondary.secondary3_100'
                      }}
                    />
                  ))}
                </Stack>
              )}
            </Stack>
          </Stack>
        }
        actions={
          <React.Fragment>
            {isDisabled ? (
              <RunningSearchAction id={id} />
            ) : (
              <RerunStack
                type={TILE_TYPE.PERSON}
                id={id}
                isOnHover={isOnHover}
                showRerun={false}
                folderId={folderId}
              />
            )}
          </React.Fragment>
        }
      />
      <PersonCardDialog
        personId={id}
        open={openPersonDialog}
        closeModal={closePersonDialog}
        isResource
      />
    </React.Fragment>
  );
}

HomePagePersonTile.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onClick: PropTypes.func,
  person: PropTypes.object
};
