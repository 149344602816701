import PropTypes from 'prop-types';
import React from 'react';

const ErrorIcon = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none">
      <circle cx="12" cy="12" r="9.75" fill="#F20530" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5Z"
        fill="#F20530"
      />
      <rect x="11" y="5.86035" width="2" height="8" rx="1" fill="white" />
      <circle cx="12" cy="16.8896" r="1.25" fill="white" />
    </svg>
  );
};

ErrorIcon.propTypes = {
  size: PropTypes.string
};

const SuccessIcon = ({ size, contained, mainColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none">
      {contained && <circle cx="12" cy="12" r="9.75" fill={mainColor || '#04BD00'} />}
      {contained && (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5Z"
          fill="#04BD00"
        />
      )}
      <path
        d="M7.1377 11.663L10.8158 15.9844C10.8577 16.0336 10.9346 16.0307 10.9727 15.9786L16.8623 7.91919"
        stroke={contained ? 'white' : mainColor || '#04BD00'}
        strokeWidth="1.7"
        strokeLinecap="round"
      />
    </svg>
  );
};

SuccessIcon.propTypes = {
  size: PropTypes.string,
  contained: PropTypes.bool,
  mainColor: PropTypes.string
};

const InfoIcon = ({ size, mainColor, backgroundColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none">
      <circle
        cx="12"
        cy="12"
        r="10"
        fill={backgroundColor ? backgroundColor : '#374EF4'}
        stroke={mainColor ? mainColor : '#374EF4'}
      />
      <rect
        x="13"
        y="18.1536"
        width="2"
        height="8"
        rx="1"
        transform="rotate(-180 13 18.1536)"
        fill={mainColor ? mainColor : 'white'}
      />
      <circle
        cx="12"
        cy="7.09668"
        r="1.25"
        transform="rotate(-180 12 7.09668)"
        fill={mainColor ? mainColor : 'white'}
      />
    </svg>
  );
};

InfoIcon.propTypes = {
  size: PropTypes.string,
  mainColor: PropTypes.string,
  backgroundColor: PropTypes.string
};

export const ToastIcons = ({ type, size, mainColor, backgroundColor, contained }) => {
  if (type === 'error') {
    return <ErrorIcon size={size} />;
  }
  if (type === 'success') {
    return <SuccessIcon size={size} contained={contained} mainColor={mainColor} />;
  }
  if (type === 'info') {
    return <InfoIcon size={size} mainColor={mainColor} backgroundColor={backgroundColor} />;
  }
};

ToastIcons.propTypes = {
  type: PropTypes.oneOf(['error', 'success', 'info']),
  size: PropTypes.string,
  mainColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  contained: PropTypes.bool
};

ToastIcons.defaultProps = {
  size: '24',
  contained: true
};
