import React from 'react';

export default function GoToLinkIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.725 8.26084V11.1505C10.725 11.4059 10.6235 11.6509 10.4429 11.8315C10.2623 12.0122 10.0173 12.1137 9.76182 12.1137H4.46418C4.20872 12.1137 3.96373 12.0122 3.78309 11.8315C3.60246 11.6509 3.50098 11.4059 3.50098 11.1505V5.85282C3.50098 5.59737 3.60246 5.35237 3.78309 5.17173C3.96373 4.9911 4.20872 4.88962 4.46418 4.88962H7.3538"
        stroke="#5C5E76"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.2793 3.44482H12.1689V6.33444"
        stroke="#5C5E76"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.87207 8.74246L12.1697 3.44482"
        stroke="#5C5E76"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
