import { loadExplore } from 'actions/explore/load-explore-action';
import { updateRecentlyFinishedRun } from 'actions/update-recently-finished-run';
import { dispatch as oldDispatch } from 'hooks/AppStateProvider';
import { updateDiscoveryProgress, updateDiscoveryStatuses } from 'reducer/explore-slice';

export function monitorDiscoveryProgressAction(searchId, companyListId) {
  return (dispatch, _getState, apiClient) => {
    apiClient.discoveries.fetchDiscoveryProgress(searchId).then((res) => {
      if (!res.ok) {
        return;
      }
      if (res.ok) {
        const { progress } = res.data;
        dispatch(updateDiscoveryProgress({ searchId, progress }));
        if (progress >= 100) {
          dispatch(loadExplore(companyListId));
          oldDispatch(updateRecentlyFinishedRun(searchId, 'discoveries'));
        }
      }
    });
  };
}

export function monitorDiscoveryStatusesAction(searchId) {
  return (dispatch, _getState, apiClient) => {
    apiClient.discoveries.getDiscoveryStatuses(searchId).then((res) => {
      if (!res.ok) {
        return;
      }
      if (res.ok) {
        const { statuses } = res.data;
        dispatch(updateDiscoveryStatuses({ searchId, statuses }));
      }
    });
  };
}
