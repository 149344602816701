import AddIcon from '@mui/icons-material/Add';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { makeNewViewerUserInOrgAction } from 'actions/organizations/make-new-viewer-user-in-org-action';
import ModalComponent from 'components/modal/ModalComponent';
import { TOAST_TYPES } from 'constants/toasts';
import { dispatch as oldDispatch } from 'hooks/AppStateProvider';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

function AddViewerUserDialog({ open, onClose, onAddUserStart, onAddUserEnd }) {
  const [userEmail, setUserEmail] = useState('');

  const closeDialog = () => {
    setUserEmail('');
    onClose();
  };

  const handleUserEmailChange = (event) => {
    setUserEmail(event.target.value);
  };

  const handleAddUser = () => {
    oldDispatch(
      makeNewViewerUserInOrgAction(
        userEmail,
        (error) => onAddUserEnd(TOAST_TYPES.ERROR, error),
        (message) => onAddUserEnd(TOAST_TYPES.SUCCESS, message)
      )
    );
    onAddUserStart();
    closeDialog();
  };

  return (
    <ModalComponent
      open={open}
      maxWidth={450}
      onClose={closeDialog}
      title="Add Viewer User"
      bottomBar={
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: '100%', padding: '8px' }}>
          <Button variant="outlined" onClick={closeDialog}>
            Cancel
          </Button>
          <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddUser}>
            Add Viewer User
          </Button>
        </Stack>
      }>
      <Stack direction="column" padding="24px" gap="16px">
        <Typography variant="paragraph">
          Add the viewer email and we will send him invitation email
        </Typography>
        <TextField
          value={userEmail}
          onChange={handleUserEmailChange}
          placeholder="Add new basic user email address here"
        />
      </Stack>
    </ModalComponent>
  );
}

AddViewerUserDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAddUserStart: PropTypes.func,
  onAddUserEnd: PropTypes.func
};

export default AddViewerUserDialog;
