export const USER_EVENTS = Object.freeze({
  AUTOCOMPLETE: 'autocomplete',
  AUTOCOMPLETE_AMBIGUOUS_WEBSITE: 'autocomplete_ambiguous_website',
  AUTOCOMPLETE_FAILURE: 'autocomplete_failure',
  LIST_SUBMITTED: 'list_submitted',
  DISCOVERY_SUBMITTED: 'discovery_submitted',
  FORBIDDEN_RESOURCE: 'forbidden_resource',
  DISCOVERY_OPEN: 'view_list_details',
  FOLDER_OPEN: 'folder_details',
  BENCHMARK_SUBMITTED: 'benchmark_submitted',
  BENCHMARK_OPEN: 'view_benchmark_details',
  PERSON_SUBMITTED: 'person_submitted',
  PERSON_CARD_OPEN: 'person_card_open',
  PERSON_RERUN_SUBMITTED: 'person_rerun_submitted',
  DEEP_DIVE_SUBMITTED: 'New run submitted',
  DEEP_DIVE_RERUN_SUBMITTED: 'Rerun submitted',
  DEEP_DIVE_PREINDEXED_SUBMITTED: 'Preindexed run submitted',
  DEEP_DIVE_OPEN: 'View overview',
  DEEP_DIVE_CARD_OPEN: 'card_open',
  FRONTEND_ERROR: 'frontend_error',
  CSV_UPLOAD_TIME: 'csv_upload_time',
  TAGGING_SUBMITTED: 'tagging_submitted',
  PUBLIC_LINK_SUBMITTED: 'public_link_submitted',
  PPTX_DOWNLOAD: 'pptx_download',
  LONG_LOADER: 'long_loader',
  SEMANTIC_SEARCH: 'semantic_search',
  DELETED_COMPANY: 'deleted_company',
  DELETED_COMPANIES: 'deleted_companies',
  FILTERED_LIST: 'filtered_list',
  COMPANY_ADDED: 'company_added',
  COMPANIES_CSV_UPLOADED: 'companies_csv_uploaded',
  RETRY_UPDATE_FINANCE: 'retry_update_finance',
  RETRY_UPDATE_EXTRA_DATA: 'retry_update_extra_data',
  CUSTOM_COLUMN_SUBMISSION: 'custom_column_submission',
  CUSTOM_ANALYTICS_SUBMISSION: 'custom_analytics_submission',
  LIST_PRESS_EXPORT: 'list_press_export',
  COLUMN_SORTING: 'column_sorting',
  EXTRA_DATA_API_FAILED: 'extra_data_api_failed',
  BULK_CC_UPDATE_FAILED: 'bulk_custom_column_update_failed',
  DYNAMIC_CARD_SUBMISSION: 'dynamic_card_submission',
  SUBMIT_SCHEDULES: 'submit_schedules',
  REMOVE_LIST_MONITORING: 'remove_list_monitoring',
  DELETE_MONITORING_SCHEDULES: 'delete_monitoring_schedules',
  FAILING_SCROLL_TO_COLUMN: 'failing_scroll_to_column'
});

export const DISCOVERY_TYPES = Object.freeze({
  QUERY: 'query',
  PEERS: 'peers',
  URL: 'url',
  PARTNERS: 'partners'
});

export const EXPLORE_EVENTS = Object.freeze({
  SUBMIT_DISCOVERY: 'submit_discovery',
  SUBMIT_CUSTOM_ANALYTICS: 'submit_custom_analytics',
  ADD_CUSTOM_COLUMN: 'add_custom_column',
  ADD_ENRICHMENT_COLUMN: 'add_enrichment_column',
  EDIT_FILTER: 'edit_filter',
  SORT_BY: 'sort_by',
  EXPORT_LIST: 'export_list',
  SUBMIT_MONITORING: 'submit_monitoring',
  MANUAL_ADD_COMPANY: 'manual_add_company',
  MANUAL_DELETE_COMPANY: 'manual_delete_company',
  UPLOAD_COMPANIES: 'upload_companies'
});
