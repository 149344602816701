/* eslint-disable max-lines-per-function */

/* eslint-disable max-lines */
import { Stack, Typography } from '@mui/material';
import { CUSTOM_ANALYTICS_PLACEMENT } from 'constants/custom-analytics';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCompanyMetasMergedRowsByCompanyListId } from 'selectors/companyMetas';
import {
  getDeepDiveDynamicCardsByDeepDiveId,
  getDeepDiveInsightsColumnsByDeepDiveId
} from 'selectors/deep-dive';
import { getListCustomColumnsByListId, getListDomainKnowledgeByListId } from 'selectors/explore';
import { getExploreUiColumnsByListId } from 'selectors/explore-table-ui';
import { getCurrentFolderId, getCurrentFolderSupportingResources } from 'selectors/folders';
import { getUserIsDev } from 'selectors/user';
import { combineDomainKnowledge } from 'utils/domain-knowledge-utils';
import {
  getCompaniesAndColumns,
  getDeepDiveDynamicCardsMetadata,
  promptConfigToDisplayPrefix,
  promptToPromptConfig
} from 'utils/prompts-utils';

function PromptDisplayText({
  listId,
  prompt,
  answerType,
  selectedCompanies,
  deepDiveId,
  isSample,
  direction,
  gap
}) {
  const columnsSettings = useSelector((state) => getExploreUiColumnsByListId(state, listId));
  const rows = useSelector((state) => getCompanyMetasMergedRowsByCompanyListId(state, listId));
  const customColumns = useSelector((state) => getListCustomColumnsByListId(state, listId));
  const isDev = useOldStateSelector(getUserIsDev);
  const folderId = useSelector(getCurrentFolderId);
  const listDomainKnowledge = useSelector((state) => getListDomainKnowledgeByListId(state, listId));
  const folderDomainKnowledge = useSelector((state) =>
    getCurrentFolderSupportingResources(state, folderId)
  );

  const domainKnowledge = useMemo(
    () => combineDomainKnowledge(listDomainKnowledge, folderDomainKnowledge),
    [listDomainKnowledge, folderDomainKnowledge]
  );
  const deepDiveDynamicCards = useSelector((state) =>
    getDeepDiveDynamicCardsByDeepDiveId(state, deepDiveId)
  );
  const deepDiveInsights = useSelector((state) =>
    getDeepDiveInsightsColumnsByDeepDiveId(state, deepDiveId)
  );

  const question = prompt?.question || prompt;
  const answer_type = prompt?.answer_type || answerType;
  const model = prompt?.model;
  const useTable = prompt?.is_table_question;

  const placement = listId
    ? CUSTOM_ANALYTICS_PLACEMENT.EXPLORE
    : CUSTOM_ANALYTICS_PLACEMENT.DEEP_DIVE;
  const promptConfig = useMemo(
    () => promptToPromptConfig(question, answer_type, selectedCompanies, model, useTable),
    [question, answer_type, selectedCompanies, model, useTable]
  );

  const { columns } = useMemo(() => {
    if (listId) {
      return getCompaniesAndColumns(listId, rows, customColumns, columnsSettings, isDev);
    }
    if (deepDiveDynamicCards) {
      const deepDiveCards = {
        ...getDeepDiveDynamicCardsMetadata(deepDiveDynamicCards),
        ...deepDiveInsights
      };

      return { columns: deepDiveCards };
    }
    return { columns: {} };
  }, [rows, customColumns, listId, columnsSettings, isDev, deepDiveDynamicCards, deepDiveInsights]);

  const promptDisplayPrefix = useMemo(() => {
    const localConfig = { ...promptConfig };
    return promptConfigToDisplayPrefix(localConfig, columns, domainKnowledge, placement, isSample);
  }, [promptConfig, columns, domainKnowledge, placement, isSample]);

  return (
    <Stack
      direction={direction}
      alignItems={direction === 'row' ? 'center' : null}
      gap={gap}
      flexWrap="wrap">
      {promptDisplayPrefix && (
        <Typography textAlign="left" variant="paragraphMedium">
          {promptDisplayPrefix}
        </Typography>
      )}
      <Typography textAlign="left" variant="paragraph">
        {promptConfig.question}
      </Typography>
    </Stack>
  );
}

PromptDisplayText.propTypes = {
  listId: PropTypes.number,
  prompt: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  answerType: PropTypes.string,
  selectedCompanies: PropTypes.array,
  deepDiveId: PropTypes.number,
  isSample: PropTypes.bool,
  direction: PropTypes.string,
  gap: PropTypes.string
};

PromptDisplayText.defaultProps = {
  direction: 'row',
  gap: '4px'
};

export default PromptDisplayText;
