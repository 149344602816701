import { Button, Stack, TextField, Typography } from '@mui/material';
import { updateOrganizationMaxViewersAction } from 'actions/organizations/update-organization-max-viewers-action';
import ModalComponent from 'components/modal/ModalComponent';
import { dispatch as oldDispatch } from 'hooks/AppStateProvider';
import PropTypes from 'prop-types';
import { React, useEffect, useState } from 'react';

function UpdateOrganizationMaxViewer({ open, organization, onClose }) {
  const [newMaxViewers, setNewMaxViewers] = useState(10);

  useEffect(() => {
    if (organization?.maxViewers >= 0) {
      setNewMaxViewers(organization.maxViewers);
    }
  }, [organization]);

  const closeDialog = () => {
    setNewMaxViewers(10);
    onClose();
  };

  const handleUpdateMaxViewers = () => {
    oldDispatch(updateOrganizationMaxViewersAction(organization.id, newMaxViewers));
    closeDialog();
  };

  return (
    <ModalComponent
      title="Update Max Viewers"
      open={open}
      maxWidth={420}
      onClose={closeDialog}
      bottomBar={
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: '100%', padding: '8px' }}>
          <Button variant="outlined" onClick={closeDialog}>
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={newMaxViewers === organization?.maxViewers}
            onClick={handleUpdateMaxViewers}>
            Update
          </Button>
        </Stack>
      }>
      <Stack direction="column" gap="16px" padding="24px">
        <Typography variant="paragraph">{`Set new maxViewers for ${organization?.name}, current value: `}</Typography>
        <Typography variant="paragraphSemiBold">{organization?.maxViewers}</Typography>
        <TextField
          variant="outlined"
          type="number"
          value={newMaxViewers}
          onChange={(event) => setNewMaxViewers(parseInt(event.target.value, 10))}
        />
      </Stack>
    </ModalComponent>
  );
}

UpdateOrganizationMaxViewer.propTypes = {
  open: PropTypes.bool,
  organization: PropTypes.object,
  onClose: PropTypes.func
};

export default UpdateOrganizationMaxViewer;
