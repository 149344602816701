import CheckBoxIcon from '@mui/icons-material/CheckBox';
import LinkIcon from '@mui/icons-material/Link';
import PinIcon from '@mui/icons-material/Pin';
import RttIcon from '@mui/icons-material/Rtt';
import { useAppUtils } from 'AppUtilsProvider';
import { loadAllCompaniesFiltersAction } from 'actions/company_lists/load-all-companies-filters-action';
import { addEmptyCustomAnalyticsColumnAction } from 'actions/custom-analytics/add-empty-custom-analytics-column-action';
import { createNewDiscoveryInCustomAnalyticsThreadAction } from 'actions/custom-analytics/create-new-discovery-in-custom-analytics-thread-action';
import { createNewPostInCustomAnalyticsThreadAsyncAction } from 'actions/custom-analytics/create-new-post-in-custom-analytics-thread-async-action';
import { sendExploreEvent } from 'actions/users/send-user-event-action';
import SelectWithSearchComponent from 'components/SelectWithSearchComponent';
import { RESOURCE_TYPE_TO_ICON } from 'components/domain-knowledge/DomainKnowledgeResourceTile';
import { CUSTOM_ANALYTICS_PLACEMENT } from 'constants/custom-analytics';
import { DISCOVERY_TYPES } from 'constants/discovery';
import { DOMAIN_KNOWLEDGE_RESOURCE_OPTIONS } from 'constants/domain-knowledge';
import { SHARED_WITH_ME_FOLDER_ID } from 'constants/folders';
import { TOAST_TYPES } from 'constants/toasts';
import { USER_EVENTS } from 'constants/userEvents';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getThreadKey } from 'reducer/custom-analytics';
import { getCompanyMetasMergedRowsByCompanyListId } from 'selectors/companyMetas';
import {
  getCustomAnalyticClipboardById,
  getIsCustomAnalyticsThreadProccessingById
} from 'selectors/custom-analytics';
import {
  getDeepDiveDynamicCardsByDeepDiveId,
  getDeepDiveInsightsColumnsByDeepDiveId
} from 'selectors/deep-dive';
import {
  getCompanyMetasByListId,
  getListCustomColumnsByListId,
  getListDomainKnowledgeByListId
} from 'selectors/explore';
import {
  getExploreFilteredMetaIdsIfListFiltered,
  getExploreTableUICheckedCompaniesByListId,
  getExploreUiColumnsByListId,
  getListOrderedRenderdMetaIdsByListId
} from 'selectors/explore-table-ui';
import { getCurrentFolderId, getCurrentFolderSupportingResources } from 'selectors/folders';
import { getUserIsDev } from 'selectors/user';
import { combineDomainKnowledge } from 'utils/domain-knowledge-utils';
import { isEmpty } from 'utils/objects-utils';
import {
  INITIAL_PROMPT_CONFIG,
  determineErrorStatus,
  generatePromptConfigToggleField,
  getCompaniesAndColumns,
  getDeepDiveDynamicCardsMetadata,
  promptConfigToPrompt
} from 'utils/prompts-utils';

import CompaniesMultiSelectComponent2 from './CompaniesMultiSelectComponent2';
import CustomAnalyticsUseFilteredCompaniesDialog from './CustomAnalyticsUseFilteredCompaniesDialog';
import PromptInputComponent1 from './PromptInputComponent1';
import PromptInputComponent2 from './PromptInputComponent2';
import SetDiscoveryByPartnership from './SetDiscoveryByPartnership';
import SlimSelectComponent from './SlimSelectComponent';

const MAX_NUMBER_OF_COMPANIES = 500;

export const ANSWER_TYPES = Object.freeze({
  text: { label: 'Text', icon: <RttIcon fontSize="small" /> },
  number: { label: 'Number', icon: <PinIcon fontSize="small" /> },
  boolean: { label: 'Yes/No', icon: <CheckBoxIcon fontSize="small" /> },
  links: { label: 'Links', icon: <LinkIcon fontSize="small" /> }
});

/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
/* eslint-disable max-statements */
function PromptInputComponent({
  listId,
  companyMetaId,
  deepDiveId,
  promptConfig,
  setPromptConfig,
  placement,
  onAddNewPost,
  mode
}) {
  const dispatch = useDispatch();
  const { addToast } = useAppUtils();
  const [filters, setFilters] = useState({});
  const [industries, setIndustries] = useState([]);
  const [partners, setPartners] = useState();
  const [showError, setShowError] = useState(false);
  const [submitOptions, setSubmitOptions] = useState();

  const folderId = useSelector(getCurrentFolderId);
  const threadKey = getThreadKey(listId, companyMetaId);
  const isProccessing = useSelector((reduxState) =>
    getIsCustomAnalyticsThreadProccessingById(reduxState, threadKey)
  );
  const rows = useSelector((state) => getCompanyMetasMergedRowsByCompanyListId(state, listId));
  const customColumns = useSelector((state) => getListCustomColumnsByListId(state, listId));
  const deepDiveDynamicCards = useSelector((state) =>
    getDeepDiveDynamicCardsByDeepDiveId(state, deepDiveId)
  );

  const isDev = useOldStateSelector(getUserIsDev);
  const columnsSettings = useSelector((state) => getExploreUiColumnsByListId(state, listId));
  const promptClipboard = useSelector((state) => getCustomAnalyticClipboardById(state, threadKey));
  const listDomainKnowledge = useSelector((state) => getListDomainKnowledgeByListId(state, listId));
  const folderDomainKnowledge = useSelector((state) =>
    getCurrentFolderSupportingResources(state, folderId)
  );

  const domainKnowledge = useMemo(
    () => combineDomainKnowledge(listDomainKnowledge, folderDomainKnowledge),
    [listDomainKnowledge, folderDomainKnowledge]
  );
  const selectedCompanies = useSelector((state) =>
    getExploreTableUICheckedCompaniesByListId(state, listId)
  );
  const numberOfComapnies = useSelector((state) => getCompanyMetasByListId(state, listId))?.length;

  const deepDiveInsights = useSelector((state) =>
    getDeepDiveInsightsColumnsByDeepDiveId(state, deepDiveId)
  );

  const filteredMetaIdsIfFiltered = useSelector((state) =>
    getExploreFilteredMetaIdsIfListFiltered(state, listId)
  );

  const orederedCompanies = useSelector((state) =>
    getListOrderedRenderdMetaIdsByListId(state, listId)
  );

  const dontAllowSubmitCA =
    isProccessing || (!promptConfig.useDiscover && promptConfig.question.trim().length <= 0);

  const { columns, companies } = useMemo(() => {
    if (placement === CUSTOM_ANALYTICS_PLACEMENT.EXPLORE) {
      return getCompaniesAndColumns(listId, rows, customColumns, columnsSettings, isDev);
    }
    if (placement === CUSTOM_ANALYTICS_PLACEMENT.DEEP_DIVE && deepDiveDynamicCards) {
      const deepDiveCards = {
        ...getDeepDiveDynamicCardsMetadata(deepDiveDynamicCards),
        ...deepDiveInsights
      };

      return { columns: deepDiveCards, companies: new Map() };
    }
    return { columns: {}, companies: new Map() };
  }, [
    rows,
    customColumns,
    listId,
    columnsSettings,
    isDev,
    deepDiveDynamicCards,
    placement,
    deepDiveInsights
  ]);

  const numberOfSelectedCompanies = useMemo(() => {
    if (!selectedCompanies) return 0;

    return Object.keys(selectedCompanies).length;
  }, [selectedCompanies]);

  useEffect(() => {
    dispatch(loadAllCompaniesFiltersAction());
  }, [dispatch]);

  useEffect(() => {
    if (isDev) {
      setPromptConfig((prev) => ({ ...prev, useGpt4: false }));
    }
  }, [isDev, setPromptConfig]);

  useEffect(() => {
    setPromptConfig((prevPromptConfig) => {
      const companiesIds = selectedCompanies ? Object.keys(selectedCompanies) : [];
      const haveAll = companiesIds.length === companies.size;
      let newCompanies = null;
      if (!haveAll && companiesIds.length > 0) {
        newCompanies = companiesIds.reduce((result, companyId) => {
          result[companyId] = companies.get(parseInt(companyId, 10));
          return result;
        }, {});
      }
      return { ...INITIAL_PROMPT_CONFIG, ...prevPromptConfig, companies: newCompanies };
    });
  }, [selectedCompanies, companies, setPromptConfig]);

  useEffect(() => {
    if (companyMetaId) {
      setPromptConfig((prevPromptConfig) => {
        return { ...prevPromptConfig, forEachCompany: true };
      });
    }
  }, [companyMetaId, setPromptConfig]);

  const handleColumnListChange = (event) => {
    const newSelectedColumn = event.target.value;
    setPromptConfig((prev) => ({ ...prev, column: newSelectedColumn }));
  };

  const handleResourceListChange2 = (newVal) => {
    setPromptConfig((prev) => ({ ...prev, useDomainKnowledge: Boolean(newVal), resource: newVal }));
  };
  const columnIds = Object.values(columns);
  const errorStatus = useMemo(
    () =>
      determineErrorStatus(
        promptConfig,
        numberOfSelectedCompanies,
        partners,
        industries,
        rows?.length
      ),
    [promptConfig, numberOfSelectedCompanies, partners, industries, rows]
  );

  const getIsInputDisabled = () => {
    if (promptConfig.useDiscover && promptConfig.discoverMethod === DISCOVERY_TYPES.PEERS) {
      return true;
    }
    return isProccessing;
  };

  useEffect(() => {
    if (errorStatus.length <= 0) {
      setShowError(false);
    }
  }, [errorStatus]);

  useEffect(() => {
    if (promptClipboard) {
      setPromptConfig(promptClipboard);
    }
  }, [promptClipboard, setPromptConfig]);

  const handleSubmitDiscovery = () => {
    const isByPartners = promptConfig.discoverMethod === DISCOVERY_TYPES.PARTNERS;
    const joinedFilters = {
      ...filters,
      industry: isByPartners ? industries : [],
      partner_db_name: isByPartners ? partners : [],
      global_table_poc: promptConfig.useGlobalTablePoc
    };
    const searchTerm =
      promptConfig.discoverMethod === DISCOVERY_TYPES.TEXT ? promptConfig.question : null;
    const url = promptConfig.discoverMethod === DISCOVERY_TYPES.URL ? promptConfig.question : null;
    dispatch(
      createNewDiscoveryInCustomAnalyticsThreadAction(
        listId,
        promptConfig.discoverMethod,
        joinedFilters,
        searchTerm,
        url
      )
    );
    setPromptConfig((prev) => ({ ...prev, question: '', answerType: 'text' }));
  };

  const sendSubmissionEvent = () => {
    dispatch(
      sendExploreEvent(
        USER_EVENTS.CUSTOM_ANALYTICS_SUBMISSION,
        listId,
        `Submitted CA with question "${promptConfig?.question}"`,
        {
          listId,
          companyMetaId,
          ...promptConfig
        }
      )
    );
  };

  const handleSubmit = (event, isSample) => {
    if (errorStatus.length > 0) {
      setShowError(true);
      return;
    }
    if (dontAllowSubmitCA) {
      return;
    }
    if (promptConfig.useDiscover) {
      handleSubmitDiscovery();
      return;
    }
    const prompt = promptConfigToPrompt(promptConfig);
    setPromptConfig((prev) => ({ ...INITIAL_PROMPT_CONFIG, ...prev, companies: null }));
    sendSubmissionEvent();

    const clearFunc = () => {
      onAddNewPost();
      setPromptConfig((prev) => ({ ...prev, question: '', answerType: 'text' }));
    };

    const createColumnFunc = (companyMetaIds) => {
      const columnName = prompt.split('}} ').pop();
      dispatch(
        addEmptyCustomAnalyticsColumnAction({
          listId,
          columnName,
          question: prompt,
          companyMetaIds,
          answerType: promptConfig.answerType,
          usePlanner: promptConfig.usePlanner,
          temperature: promptConfig.temperature,
          supportingResourceId: promptConfig.resource,
          columns: promptConfig.columns,
          folderId: folderId && folderId !== SHARED_WITH_ME_FOLDER_ID ? folderId : null,
          useTable: promptConfig.useTable
        })
      );
    };
    const submitFun = (companiesList) => {
      dispatch(
        createNewPostInCustomAnalyticsThreadAsyncAction({
          listId,
          companyMetaId,
          query: prompt,
          useColumn: promptConfig.column,
          answerType: promptConfig.answerType,
          useGpt4: promptConfig.useGpt4,
          usePlanner: promptConfig.usePlanner,
          selectedCompanies: companiesList,
          temperature: promptConfig.temperature,
          folderId: folderId && folderId !== SHARED_WITH_ME_FOLDER_ID ? folderId : null,
          supportingResourceId: promptConfig.resource,
          columns: promptConfig.columns,
          forEachCompany: promptConfig.forEachCompany,
          isSample,
          useTable: promptConfig.useTable
        })
      );
    };

    if (companyMetaId) {
      submitFun([companyMetaId]);
      clearFunc();
      return;
    }

    if (isSample) {
      let sampleCompanies = null;
      if (numberOfSelectedCompanies > 0 && numberOfSelectedCompanies <= 5) {
        sampleCompanies = Object.keys(selectedCompanies).map(
          (listMetaId) => companies.get(parseInt(listMetaId, 10)).companyMetaId
        );
      } else {
        sampleCompanies = Array.from(companies.values())
          .map((cm) => cm.companyMetaId)
          .sort((id1, id2) => orederedCompanies?.indexOf(id1) - orederedCompanies?.indexOf(id2))
          .slice(0, 5);
      }
      submitFun(sampleCompanies);
      clearFunc();
      return;
    }

    if (numberOfSelectedCompanies) {
      if (numberOfSelectedCompanies > MAX_NUMBER_OF_COMPANIES) {
        addToast(
          `The maximum number of companies is ${MAX_NUMBER_OF_COMPANIES}. You have selected ${numberOfSelectedCompanies} companies. Please select no more than ${MAX_NUMBER_OF_COMPANIES} companies`,
          TOAST_TYPES.ERROR
        );
        return;
      }
      const inputSelectedCompanies = Object.keys(selectedCompanies).map(
        (listMetaId) => companies.get(parseInt(listMetaId, 10)).companyMetaId
      );
      if (promptConfig.forEachCompany) {
        createColumnFunc(inputSelectedCompanies);
      } else {
        submitFun(inputSelectedCompanies);
      }
      clearFunc();
      return;
    }

    if (promptConfig.forEachCompany) {
      const isTooManyCompanies =
        numberOfComapnies > MAX_NUMBER_OF_COMPANIES &&
        (isEmpty(filteredMetaIdsIfFiltered) ||
          filteredMetaIdsIfFiltered?.length > MAX_NUMBER_OF_COMPANIES);

      if (isTooManyCompanies) {
        const numOfShowingCompanies = isEmpty(filteredMetaIdsIfFiltered)
          ? numberOfComapnies
          : filteredMetaIdsIfFiltered.length;
        addToast(
          `The maximum number of companies is ${MAX_NUMBER_OF_COMPANIES}. We are showing ${numOfShowingCompanies} companies. Please use screening to reduce selection to no more than ${MAX_NUMBER_OF_COMPANIES} companies`,
          TOAST_TYPES.ERROR
        );
        return;
      }
    }
    const isHasFilters =
      filteredMetaIdsIfFiltered?.length > 0 &&
      filteredMetaIdsIfFiltered?.length < numberOfComapnies;

    if (!isHasFilters) {
      if (promptConfig.forEachCompany) {
        createColumnFunc(null);
      } else {
        submitFun(null);
      }
      clearFunc();
      return;
    }

    if (promptConfig.forEachCompany) {
      if (numberOfComapnies > MAX_NUMBER_OF_COMPANIES) {
        // the only possible option to submit is to use filtered companies
        createColumnFunc(filteredMetaIdsIfFiltered);
        clearFunc();
      } else {
        // if possible to submit all companies, show dialog for user to choose
        setSubmitOptions({
          filtered: () => {
            createColumnFunc(filteredMetaIdsIfFiltered);
            clearFunc();
          },
          all: () => {
            createColumnFunc(null);
            clearFunc();
          }
        });
      }
    } else {
      // we got here if we are on ask
      setSubmitOptions({
        filtered: () => {
          submitFun(filteredMetaIdsIfFiltered);
          clearFunc();
        },
        all: () => {
          submitFun(null);
          clearFunc();
        }
      });
    }
  };

  const domainKnowledgeOptions = [
    ...DOMAIN_KNOWLEDGE_RESOURCE_OPTIONS,
    ...Object.values(domainKnowledge || {})
      .sort((item1, item2) => (item1.type < item2.type ? 1 : -1))
      .map((dk) => ({
        icon: RESOURCE_TYPE_TO_ICON[dk.type],
        ...dk
      }))
  ];
  const toggleField = generatePromptConfigToggleField(setPromptConfig, columnIds);

  const ColumnSelector = (
    <SelectWithSearchComponent
      options={columnIds}
      value={promptConfig.column?.id || promptConfig.column}
      searchPlaceHolder="Search for a column"
      onChange={handleColumnListChange}
      sx={{ maxWidth: '150px' }}
    />
  );

  const DomainKnowledgeSelector =
    mode === 1 ? null : (
      <SlimSelectComponent
        selectedValues={promptConfig.resource}
        allValues={domainKnowledgeOptions}
        onValueChange={handleResourceListChange2}
        emptyTag="Select..."
        allowNone
        sx={{ maxWidth: '150px' }}
      />
    );

  const CompaniesSelector =
    mode === 1 ? null : (
      <CompaniesMultiSelectComponent2
        listId={listId}
        placeholder="Select Peers"
        maxWidth={300}
        allCompanies={companies}
      />
    );

  const PartnersWizard = (
    <SetDiscoveryByPartnership
      filters={filters}
      industries={industries}
      setIndustries={setIndustries}
      partners={partners}
      setPartners={setPartners}
      industriesMaxWidth={420}
    />
  );

  const props = {
    listId,
    promptConfig,
    setPromptConfig,
    isDev,
    isProccessing,
    handleSubmit,
    showError,
    filters,
    setFilters,
    setShowError,
    getIsInputDisabled,
    dontAllowSubmitCA,
    companyMetaId,
    placement,
    DomainKnowledgeSelector,
    domainKnowledge,
    numberOfComapnies,
    CompaniesSelector,
    ColumnSelector,
    PartnersWizard,
    toggleField,
    columns,
    knowledge: domainKnowledgeOptions.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {}),
    errorStatus
  };
  const InnnerComponent =
    mode === 1 ? <PromptInputComponent1 {...props} /> : <PromptInputComponent2 {...props} />;

  return (
    <React.Fragment>
      {InnnerComponent}
      <CustomAnalyticsUseFilteredCompaniesDialog
        totalNumOfComapnies={numberOfComapnies}
        filteredNumOfCompanies={filteredMetaIdsIfFiltered?.length}
        submitOptions={submitOptions}
        open={Boolean(submitOptions)}
        onClose={() => setSubmitOptions(null)}
      />
    </React.Fragment>
  );
}

PromptInputComponent.propTypes = {
  listId: PropTypes.number,
  companyMetaId: PropTypes.number,
  promptConfig: PropTypes.object.isRequired,
  setPromptConfig: PropTypes.func.isRequired,
  mode: PropTypes.oneOf([1, 2]),
  placement: PropTypes.string,
  onAddNewPost: PropTypes.func,
  deepDiveId: PropTypes.number
};

export default PromptInputComponent;
