import PropTypes from 'prop-types';

export default function ExportIcon({ color }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.3619 14.5254V17.4589C18.3619 17.8479 18.2074 18.221 17.9323 18.4961C17.6573 18.7711 17.2842 18.9257 16.8952 18.9257H6.62789C6.23888 18.9257 5.86581 18.7711 5.59074 18.4961C5.31567 18.221 5.16113 17.8479 5.16113 17.4589V14.5254"
        stroke={color || 'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4285 9.39174L11.7616 5.72485L8.09473 9.39174"
        stroke={color || 'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7617 5.72485V14.5254"
        stroke={color || 'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

ExportIcon.propTypes = {
  color: PropTypes.string
};
