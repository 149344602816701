import { editDiscoveryCustomColumnNameAction } from 'actions/explore/edit-discovery-custom-column-name-action';

export function generateNameFromQuery(question) {
  return (dispatch, getState, apiClient) => {
    return apiClient.llm_queries.generateNameForColumn(question);
  };
}
export function generateColumnNameAction(listId, newColumnId, question) {
  return (dispatch, getState, apiClient) => {
    apiClient.llm_queries.generateNameForColumn(question).then((response) => {
      if (response.ok && response.data) {
        if (response.data.answer) {
          dispatch(
            editDiscoveryCustomColumnNameAction(listId, newColumnId, response.data.answer, true)
          );
        }
      }
    });
  };
}
