import { createSlice } from '@reduxjs/toolkit';
import { RESOURCE_ID_TYPE_TO_PERMISSIONS_SLICE_LOCATION } from 'constants/resources';

const initialSliceState = {
  lists: {},
  records: {},
  folders: {},
  savedQueries: {}
};

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: initialSliceState,
  reducers: {
    updateResourcePermissions: (sliceState, { payload }) => {
      const { id, resourceIdType, permissions } = payload;
      sliceState[RESOURCE_ID_TYPE_TO_PERMISSIONS_SLICE_LOCATION[resourceIdType]][id] = permissions;
    }
  }
});

export const { updateResourcePermissions } = permissionsSlice.actions;

export default permissionsSlice.reducer;
