import { Avatar, Chip, Stack, Tooltip, Typography } from '@mui/material';
import BarChartComponent from 'components/elements/bar/BarChartComponent';
import GoToLinkIcon from 'icons/GoToLinkIcon';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { appTheme } from 'theme';
import { formatStrRevenueFromData, revenueCategoryFromData } from 'utils/finance-utils';
import { underscoreToTitle } from 'utils/string-utils';

import QuickViewBox from './QuickViewBox';
import QuickViewDivider from './QuickViewDivider';
import QuickViewFieldValue from './QuickViewFieldValue';

const barColor = appTheme.palette.graphColors.graph10;
const transparentBarColor = '#E0CEE4';

const renderInvestors = (investors) => {
  if (!investors) return null;
  return (
    <Stack direction="row" gap="8px" sx={{ flexWrap: 'wrap' }}>
      {investors.map((investor, index) => (
        <Chip
          key={index}
          size="small"
          label={investor.name}
          sx={{ height: '24px' }}
          avatar={<Avatar src={investor.logo_url} variant="rounded" />}
        />
      ))}
    </Stack>
  );
};

function QuickViewFinancials({ row, revenuesData }) {
  const { revenues: revenuesOuter, lead_investors, last_equity_round_message, total_funding } = row;
  const { data: revenues } = revenuesOuter || {};
  const isEstimated = revenues?.consensus?.is_estimated;
  const finalValue = useMemo(() => {
    return formatStrRevenueFromData(revenues?.consensus);
  }, [revenues]);
  const targetCategory = revenueCategoryFromData(revenues);
  const barData =
    revenuesData &&
    revenuesData
      .filter((revenue) => revenue?.data?.consensus)
      .map((revenue) => {
        return revenueCategoryFromData(revenue?.data);
      })
      .filter((category) => category)
      .sort((item1, item2) => item1.index - item2.index)
      .reduce((acc, item) => {
        if (acc?.[item?.label]?.value) {
          acc[item.label].value += 1;
        } else {
          acc[item?.label] = { value: 1, index: item.index };
        }
        return acc;
      }, {});
  const barColors =
    barData &&
    Object.keys(barData).reduce((acc, label) => {
      const color =
        targetCategory && barData[label].index === targetCategory.index
          ? barColor
          : transparentBarColor;
      acc[label] = color;
      return acc;
    }, {});

  const revenuesToDisplay =
    revenues &&
    Object.values(revenues).length > 0 &&
    Object.values(revenues).filter(
      (source) =>
        source && source.source_name !== 'consensus' && source.is_estimated === isEstimated
    );
  return (
    <QuickViewBox title="Financials">
      <Stack direction="row" width="100%" minHeight="330px" gap="24px">
        <Stack minWidth="256px" maxWidth="256px" gap="24px">
          <Stack gap="12px">
            <Typography variant="paragraphSemiBold">Revenue by sources</Typography>
            {revenuesToDisplay ? (
              revenuesToDisplay.map((source, index) => (
                <QuickViewFieldValue
                  key={index}
                  direction="row"
                  endAdornment={<GoToLinkIcon />}
                  field={underscoreToTitle(source.source_name)}
                  value={formatStrRevenueFromData(source)}
                  href={source.link_to_source}
                />
              ))
            ) : (
              <Typography variant="paragraph">Unkown</Typography>
            )}
          </Stack>
          <QuickViewFieldValue
            gap="4px"
            direction="column"
            field="Total funding"
            value={total_funding}
          />
          {lead_investors && (
            <QuickViewFieldValue
              gap="8px"
              direction="column"
              field="Lead investors"
              value={lead_investors}
              renderVal={renderInvestors}
            />
          )}
          <QuickViewFieldValue
            direction="column"
            gap="4px"
            field="Funding details"
            value={last_equity_round_message}
          />
        </Stack>
        <QuickViewDivider orientation="vertical" />
        <Stack width="100%" gap="0px">
          <Stack direction="row" justifyContent="space-between">
            <Stack>
              <Typography variant="paragraphBold">Revenue relative to list</Typography>
              {targetCategory ? (
                <Typography variant="paragraph">
                  {row.company_name.name} is part of the <strong>{targetCategory.label}</strong>{' '}
                  revenue group
                </Typography>
              ) : (
                <Typography variant="paragraph">
                  {row.company_name.name} has no revenue information
                </Typography>
              )}
            </Stack>
            <Stack direction="row" alignItems="center" gap="4px">
              <Typography variant="mediumNumber" noWrap display="block">
                {finalValue}
              </Typography>
              {revenues?.consensus && (
                <Tooltip title={isEstimated ? 'Estimated' : 'Actual'} arrow>
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      width: '23px',
                      height: '27px',
                      backgroundColor: 'background.bg100',
                      borderRadius: '4px',
                      padding: '6px'
                    }}>
                    <Typography color="greyColors.grey300" variant="h3">
                      {isEstimated ? 'E' : 'A'}
                    </Typography>
                  </Stack>
                </Tooltip>
              )}
            </Stack>
          </Stack>
          <Stack width="600px" marginLeft="-40px">
            <BarChartComponent
              labels={Object.keys(barData)}
              values={Object.values(barData).map((item) => item.value)}
              colors={barColors}
              noXLabel
              yAxisName="Company count"
            />
          </Stack>
        </Stack>
      </Stack>
    </QuickViewBox>
  );
}

QuickViewFinancials.propTypes = {
  row: PropTypes.object,
  revenuesData: PropTypes.array
};

export default QuickViewFinancials;
