import { get, httpDelete, post, put } from './base-client';

export default class BaseClient {
  constructor(setErrorConfig) {
    this.setErrorConfig = setErrorConfig;
  }

  get(path, contentType) {
    return get(path, this.setErrorConfig, contentType);
  }

  put(path, data) {
    return put(path, data || {}, this.setErrorConfig);
  }

  post(path, data, contentType) {
    return post(path, data || {}, this.setErrorConfig, contentType);
  }

  httpDelete(path) {
    return httpDelete(path, this.setErrorConfig);
  }
}
