export const COMPANY_EXTRA_DATA_TYPES = Object.freeze({
  PROXYCURL: 'PROXYCURL',
  LEADERS: 'LEADERS',
  SUMMARY: 'SUMMARY',
  VECTORISATION: 'VECTORISATION',
  REVENUES: 'REVENUES',
  ENRICHMENT_PARAMETERS: 'ENRICHMENT_PARAMETERS'
});

export const OVERRIDE_STATUS_FIELD = Object.freeze({
  [COMPANY_EXTRA_DATA_TYPES.PROXYCURL]: null
});

export const OVERRIDE_TYPE_TO_FIELD_MAP = Object.freeze({
  [COMPANY_EXTRA_DATA_TYPES.PROXYCURL]: 'enrichment_parameters',
  [COMPANY_EXTRA_DATA_TYPES.ENRICHMENT_PARAMETERS]: 'enrichment_parameters'
});

export const DATA_TYPE_TO_FEATURE_FLAG = Object.freeze({
  // for feature flag for column use the feature flag name from COLUMN_TYPE_TO_FEATURE_FLAG_NAME like this:
  // [COMPANY_EXTRA_DATA_TYPES.LEADERS]: COLUMN_TYPE_TO_FEATURE_FLAG_NAME.LEADERS
});
