import { TOAST_TYPES } from 'constants/toasts';
import { getThreadKey, updatePromptClipboard } from 'reducer/custom-analytics';
import { getCompanyMetasMergedRowsByCompanyListId } from 'selectors/companyMetas';
import { getExploreTableUICheckedCompaniesByListId } from 'selectors/explore-table-ui';
import { isEmpty } from 'utils/objects-utils';
import { promptToPromptConfig } from 'utils/prompts-utils';

export function updatePromptClipboardActions(
  listId,
  companyMetaId,
  { question, answer_type, model, is_table_question },
  addToast
) {
  return (dispatch, getState) => {
    const state = getState();
    const threadKey = getThreadKey(listId, companyMetaId);
    let newCompanies = null;

    if (listId) {
      const selectedCompanies = getExploreTableUICheckedCompaniesByListId(state, listId);
      const rows = getCompanyMetasMergedRowsByCompanyListId(state, listId);
      const companiesIds = selectedCompanies ? new Set(Object.keys(selectedCompanies)) : null;
      const haveAll = isEmpty(selectedCompanies) || companiesIds.size === rows.length;
      if (!haveAll) {
        newCompanies = rows
          .filter((company) => companiesIds.has(company.companyListMetaId.toString()))
          ?.reduce((acc, company) => {
            return {
              ...acc,
              [company.companyListMetaId]: {
                id: company.companyListMetaId,
                name: company.name,
                companyMetaId: company.companyMetaId,
                logoUrl: company.logo_url
              }
            };
          }, {});
      }
    }

    const promptConfig = promptToPromptConfig(
      question,
      answer_type,
      newCompanies,
      model,
      is_table_question
    );

    dispatch(updatePromptClipboard({ threadKey, promptConfig }));
    addToast?.('Question settings copied to current search', TOAST_TYPES.SUCCESS);
  };
}
