import PropTypes from 'prop-types';
import React from 'react';

function BookmarkIcon({ isBookmarked, disabled }) {
  const color = disabled ? '#BDBDBD' : '#584CA7';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M18.6667 20L12.8333 15.8333L7 20V6.66667C7 6.22464 7.17559 5.80072 7.48816 5.48816C7.80072 5.17559 8.22464 5 8.66667 5H17C17.442 5 17.866 5.17559 18.1785 5.48816C18.4911 5.80072 18.6667 6.22464 18.6667 6.66667V20Z"
        stroke={isBookmarked ? color : 'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={isBookmarked ? color : 'none'}
      />
    </svg>
  );
}

BookmarkIcon.propTypes = {
  isBookmarked: PropTypes.bool,
  disabled: PropTypes.bool
};
export default BookmarkIcon;
