import { updateExploreCustomColumnInfo } from 'reducer/explore-slice';
import { getListCustomColumnByListIdAndColumnId } from 'selectors/explore';

export function editDiscoveryCustomColumnInfoAction(
  listId,
  columnId,
  newColumnInfo,
  useOld = false
) {
  return (dispatch, getState, apiClient) => {
    let infoToUse = newColumnInfo;
    if (useOld) {
      const state = getState();
      const columnInfo =
        getListCustomColumnByListIdAndColumnId(state, listId, columnId)?.info || {};
      infoToUse = { ...columnInfo, ...newColumnInfo };
    }
    apiClient.companiesLists.updateCustomColumnInfo(listId, columnId, infoToUse);
    dispatch(updateExploreCustomColumnInfo({ listId, columnId, newColumnInfo: infoToUse }));
  };
}
