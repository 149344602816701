import {
  Box,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import SelectMultipleMenuItemInner from 'components/SelectMultipleMenuItemInner';
import ArrowIcon from 'icons/ArrowIcon';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const MenuItemSyle = {
  '&.Mui-selected': {
    backgroundColor: 'white'
  },
  '&.Mui-selected:focus': {
    backgroundColor: 'white'
  },
  '&.Mui-selected:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  },
  paddingLeft: '4px',
  width: '100%'
};
const MenuProps = {
  PaperProps: {
    style: {
      minWidth: 200,
      maxWidth: 300,
      padding: '0px 8px'
    }
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 100
  }
};

function SlimMultiSelectPromptInputComponent({
  selectedValues,
  allTag,
  emptyTag,
  onValueChange,
  sx,
  selectStyle,
  knowledge,
  defualtSources
}) {
  const additionalSources = useMemo(() => {
    if (!knowledge) {
      return null;
    }
    return [
      {
        id: 'domainKnowledge',
        name: 'Domain knowledge',
        disabled:
          !knowledge ||
          !Object.values(knowledge).find((item) => Boolean(item.supporting_resource_type)),
        disabledMessage: 'There is no domain knowledge added yet'
      }
    ];
  }, [knowledge]);

  const isSearchEverywhere = defualtSources?.every((value) => selectedValues.includes(value.id));
  const isSearchAdditional = additionalSources?.filter((value) =>
    selectedValues.includes(value.id)
  )?.length;

  const searchAdditionalChip = isSearchAdditional
    ? `+${additionalSources?.filter((value) => selectedValues.includes(value.id))?.length}`
    : '';

  const handleChange = (event) => {
    const newHaveAll = event.target.value.includes(allTag);

    if (newHaveAll) {
      const defaultSourcesIds = defualtSources.map((value) => value.id);
      const selectedWithoutDefaultSources = event.target.value.filter(
        (value) => !defaultSourcesIds.includes(value)
      );
      const newVal = isSearchEverywhere
        ? selectedWithoutDefaultSources
        : [...selectedWithoutDefaultSources, ...defaultSourcesIds];
      onValueChange(newVal);
    } else {
      const value = event.target?.value;
      // On autofill we get a stringified value.
      const newValue = typeof value === 'string' ? value.split(',') : value;
      onValueChange(newValue);
    }
  };

  return (
    <Stack gap="4px" textAlign="left" sx={sx}>
      <FormControl>
        <Select
          value={selectedValues || []}
          multiple
          displayEmpty
          MenuProps={MenuProps}
          IconComponent={() => {
            return (
              <Box
                sx={{
                  position: 'absolute',
                  cursor: 'pointer',
                  pointerEvents: 'none',
                  right: 14,
                  top: 5,
                  height: '15px',
                  width: '15px'
                }}>
                <ArrowIcon rotation="180" />
              </Box>
            );
          }}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return (
                <Typography variant="paragraphMedium" color="greyColors.grey150">
                  {emptyTag}
                </Typography>
              );
            }
            if (isSearchEverywhere) {
              return (
                <Typography variant="paragraphMedium" color="greyColors.grey300">
                  {allTag} {searchAdditionalChip}
                </Typography>
              );
            }
            if (
              defualtSources?.filter((value) => selectedValues.includes(value.id))?.length === 1
            ) {
              return (
                <Typography variant="paragraphMedium" color="greyColors.grey300">
                  Search{' '}
                  {defualtSources
                    ?.filter((value) => selectedValues.includes(value.id))[0]
                    .name.toLowerCase()}{' '}
                  {searchAdditionalChip}
                </Typography>
              );
            }
            if (
              additionalSources?.filter((value) => selectedValues.includes(value.id)?.length === 1)
            ) {
              return (
                <Typography variant="paragraphMedium" color="greyColors.grey300">
                  Search{' '}
                  {additionalSources
                    ?.filter((value) => selectedValues.includes(value.id))[0]
                    .name.toLowerCase()}{' '}
                </Typography>
              );
            }
            return (
              <Typography variant="paragraphMedium" color="greyColors.grey300">
                {selected.length} selected
              </Typography>
            );
          }}
          sx={selectStyle}
          onChange={handleChange}>
          {defualtSources && (
            <MenuItem value={allTag} sx={MenuItemSyle}>
              <Stack direction="row" gap="4px" alignItems="center">
                <Checkbox checked={isSearchEverywhere} />
                <Typography variant="paragraphMedium" color="greyColors.grey300">
                  Search everywhere
                </Typography>
              </Stack>
            </MenuItem>
          )}
          {defualtSources &&
            defualtSources.map((item) => (
              <MenuItem key={item.id} value={item.id} disabled={item.disabled} sx={MenuItemSyle}>
                <Box paddingLeft="24px">
                  <SelectMultipleMenuItemInner
                    value={item.id}
                    label={item.name}
                    selectedValues={selectedValues || []}
                    checkbox
                  />
                </Box>
              </MenuItem>
            ))}
          {additionalSources && (
            <Box paddingTop="8px">
              <Typography variant="paragraphBold" color="greyColors.grey300" paddingLeft="16px">
                Additional Sources
              </Typography>
            </Box>
          )}
          {additionalSources &&
            additionalSources.map((item) => {
              const child = (
                <MenuItem key={item.id} value={item.id} disabled={item.disabled} sx={MenuItemSyle}>
                  <SelectMultipleMenuItemInner
                    value={item.id}
                    label={item.name}
                    selectedValues={selectedValues || []}
                    checkbox
                  />
                </MenuItem>
              );
              if (item.disabled && item.disabledMessage) {
                return (
                  <Tooltip
                    title={item.disabledMessage}
                    key={item.id}
                    arrow
                    PopperProps={{
                      modifiers: [{ name: 'offset', options: { offset: [0, -14] } }]
                    }}>
                    <span>{child}</span>
                  </Tooltip>
                );
              }
              return child;
            })}
        </Select>
      </FormControl>
    </Stack>
  );
}

SlimMultiSelectPromptInputComponent.propTypes = {
  maxWidth: PropTypes.number,
  selectedValues: PropTypes.array,
  onValueChange: PropTypes.func,
  allTag: PropTypes.string,
  inputLabel: PropTypes.string,
  emptyTag: PropTypes.string,
  sx: PropTypes.object,
  selectStyle: PropTypes.object,
  knowledge: PropTypes.object,
  defualtSources: PropTypes.array
};

SlimMultiSelectPromptInputComponent.defaultProps = {
  emptyTag: '',
  sx: {}
};

export default SlimMultiSelectPromptInputComponent;
