import React from 'react';

export default function UpIcon() {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.96484 12.9166V4.58325M8.96484 4.58325L12.9648 8.58325M8.96484 4.58325L4.96484 8.58325"
        stroke="#2A2A2A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
