import { Stack, Typography } from '@mui/material';
import { highlightColumnAction } from 'actions/explore-table-ui/highlight-column-action';
import CustomMarkdown from 'components/CustomMarkdown';
import { CUSTOM_ANALYTICS_PLACEMENT } from 'constants/custom-analytics';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDeepDiveDynamicCardByDeepDiveIdAndCardId } from 'selectors/deep-dive';
import { getListCustomColumnsByListId } from 'selectors/explore';
import { COLUMN_TYPE_TO_TITLE } from 'utils/explore-table-columns';
import { insightTypeToTitle } from 'utils/insights-utils';

function CustomAnalyticsSourceItemComponent({ listId, deepDiveId, source, placement }) {
  const dispatch = useDispatch();

  const id = source.substring(10, source.length - 2);
  const dynamicCard = useSelector((state) =>
    getDeepDiveDynamicCardByDeepDiveIdAndCardId(state, deepDiveId, id)
  );
  const customColumns = useSelector((state) => getListCustomColumnsByListId(state, listId));

  const customColumnsIdToName = useMemo(() => {
    if (!customColumns) return {};
    return Object.values(customColumns).reduce((result, customColumn) => {
      result[customColumn.id] = customColumn.name;
      return result;
    }, {});
  }, [customColumns]);
  const handleSource = (sourceId) => {
    if (listId) {
      dispatch(highlightColumnAction(listId, sourceId, true));
    }
  };

  if (source.startsWith('private:')) {
    let title = null;
    const titles = [];
    if (placement === CUSTOM_ANALYTICS_PLACEMENT.DEEP_DIVE) {
      title = { id, text: dynamicCard ? dynamicCard.data?.name : insightTypeToTitle(id) };
    } else {
      titles.push(
        ...id.split(',').map((sourceId) => {
          const titleRow = sourceId.replace(/['" ]+/gu, '');
          return {
            id: titleRow,
            text:
              COLUMN_TYPE_TO_TITLE[titleRow] ||
              customColumnsIdToName[titleRow] ||
              titleRow
                .split('_')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                ?.join(' ')
          };
        })
      );
    }

    return (
      <Typography variant="paragraphMedium" textAlign="start" color="primary.primary200">
        {`Using ${placement === CUSTOM_ANALYTICS_PLACEMENT.DEEP_DIVE ? 'card' : 'column'}: `}
        {title && (
          <Typography
            variant="paragraphBold"
            textAlign="start"
            color="primary.primary3_50"
            sx={{ cursor: 'pointer' }}
            onClick={() => handleSource(title.id)}>
            {title.text}
          </Typography>
        )}
        {titles.map((titleRow, index) => (
          <React.Fragment key={titleRow.id}>
            <Typography
              variant="paragraphBold"
              textAlign="start"
              color="primary.primary3_50"
              sx={{ cursor: 'pointer' }}
              onClick={() => handleSource(titleRow.id)}>
              {titleRow?.text}
            </Typography>
            {index < titles.length - 1 && ' ,'}
          </React.Fragment>
        ))}
      </Typography>
    );
  } else if (source.startsWith('files:')) {
    const files = JSON.parse(source.substring(6));
    return (
      <Stack>
        {files.map((file) => (
          <Typography
            key={file.file_name.concat(file.id)}
            variant="paragraphMedium"
            textAlign="start"
            color="primary.primary200">
            {`Using file: `}
            <Typography variant="paragraphBold" textAlign="start" color="primary.primary200">
              {file.file_name}
            </Typography>
          </Typography>
        ))}
      </Stack>
    );
  }
  return <CustomMarkdown body={source} listId={listId} />;
}

CustomAnalyticsSourceItemComponent.propTypes = {
  listId: PropTypes.number,
  deepDiveId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  source: PropTypes.string,
  placement: PropTypes.oneOf(Object.values(CUSTOM_ANALYTICS_PLACEMENT))
};

export default CustomAnalyticsSourceItemComponent;
