import React from 'react';

export default function MaximizeIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2129 5.36255H18.638V9.7877"
        stroke="#3B3B3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.78745 18.6378H5.3623V14.2126"
        stroke="#3B3B3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6383 5.36255L13.4756 10.5252"
        stroke="#3B3B3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.3623 18.6378L10.525 13.4751"
        stroke="#3B3B3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
