import { TOAST_TYPES } from 'constants/toasts';
import { removeSavedQuery } from 'reducer/saved-queries-slice';

import { loadSavedQueriesAction } from './load-saved-queries-action';

export function deleteSavedQueryAction(savedQueryId, addToast) {
  return (dispatch, _getState, apiClient) => {
    dispatch(removeSavedQuery({ savedQueryId }));
    apiClient.saved_queries
      .deleteSavedQuery(savedQueryId)
      .then((res) => {
        if (res.ok) {
          addToast?.('Query deleted', TOAST_TYPES.SUCCESS);
        } else {
          addToast?.('Failed to delete query', TOAST_TYPES.ERROR);
          dispatch(loadSavedQueriesAction());
        }
      })
      .catch(() => {
        addToast?.('Failed to delete query', TOAST_TYPES.ERROR);
        dispatch(loadSavedQueriesAction());
      });
  };
}
