export function formatNumberToMil(number) {
  if (!number) return null;
  const numInMil = Math.round(number / 1e6);
  return numInMil.toLocaleString();
}

export function formatNumberToBil(number) {
  if (!number) return null;
  const numInMil = Math.round(number / 1e9);
  return numInMil.toLocaleString();
}

export function formatNumberToK(number) {
  if (!number) return null;
  const numInMil = Math.round(number / 1e3);
  return numInMil.toLocaleString();
}

export function formatNumberTo10k(number) {
  if (!number) return null;
  const numIn10k = Math.round(number / 1e4) * 10;
  return numIn10k.toLocaleString();
}

export function formatRevenueFromData(data) {
  if (data?.consensus?.clean_revenue_amount) {
    const numInMil = formatNumberToMil(data?.consensus?.clean_revenue_amount);
    if (numInMil === '0') {
      return '<1';
    }
    return numInMil;
  }
  if (data?.consensus?.revenue_range_lower) {
    return `>${formatNumberToMil(data?.consensus?.revenue_range_lower)}`;
  }
  if (data?.consensus?.revenue_range_upper) {
    return `<${formatNumberToMil(data?.consensus?.revenue_range_upper)}`;
  }
  return null;
}

function formatStrRevenueFromNumber(revenue) {
  if (revenue) {
    const numInBil = formatNumberToBil(revenue);
    if (numInBil === '0') {
      const numInMil = formatNumberToMil(revenue);
      if (numInMil === '0') {
        const numInK = formatNumberToK(revenue);
        if (numInK === '0') {
          return '<$1K';
        }
        return `$${numInK}K`;
      }
      return `$${numInMil}M`;
    }
    return `$${numInBil}B`;
  }
  return null;
}

export function formatStrRevenueFromData(data) {
  if (!data) return null;
  const { clean_revenue_amount, revenue_range_lower, revenue_range_upper } = data;
  if (!clean_revenue_amount && !revenue_range_lower && !revenue_range_upper) return null;
  const formattedRevenue = formatStrRevenueFromNumber(
    clean_revenue_amount || revenue_range_lower || revenue_range_upper
  );
  if (clean_revenue_amount) {
    return formattedRevenue;
  }
  if (revenue_range_lower) {
    return `> ${formattedRevenue}`;
  }
  return `< ${formattedRevenue}`;
}

export function toFixedIfNeeded(num) {
  const zerosPattern = /[.,]00$/u;
  return num.toFixed(2).replace(zerosPattern, '');
}

export function formatNumber(number) {
  if (!number) return null;

  if (Math.abs(number) >= 1e9) {
    return `${toFixedIfNeeded(number / 1e9)}B`;
  } else if (Math.abs(number) >= 1e6) {
    return `${toFixedIfNeeded(number / 1e6)}M`;
  } else if (Math.abs(number) >= 1e3) {
    return `${toFixedIfNeeded(number / 1e3)}K`;
  }

  return toFixedIfNeeded(number);
}

export function formatRevenueForPeers(revenue) {
  if (revenue) {
    const numInMil = formatNumberToMil(revenue);
    if (numInMil === '0') {
      return '<1';
    }
    return numInMil;
  }
  return null;
}

export function formatRevenueForPeersPerPerson(revenue) {
  if (revenue) {
    const numIn10k = formatNumberTo10k(revenue);
    if (numIn10k === '0') {
      return '<1';
    }
    return numIn10k;
  }
  return null;
}

export function revenueCategoryFromData(data) {
  if (!data) return null;
  const { consensus } = data;
  if (!consensus) return null;
  const { clean_revenue_amount, revenue_range_lower, revenue_range_upper } = consensus;
  const revenue = clean_revenue_amount || revenue_range_lower || revenue_range_upper;
  if (!revenue) return null;
  const power = Math.floor(Math.log10(revenue));
  let lower = 10 ** power;
  let upper = 10 ** (power + 1);
  let label = `${formatStrRevenueFromNumber(lower)}-${formatStrRevenueFromNumber(upper)}`;
  if (power < 6) {
    lower = 0;
    upper = 1e6;
    label = '<$1M';
  } else if (power > 8) {
    lower = 1e9;
    upper = 1e15;
    label = '>$1B';
  }
  return {
    index: power,
    range: [lower, upper],
    label
  };
}

export function revenueCategoryStrFromData(data) {
  const category = revenueCategoryFromData(data);
  if (!category) return null;
  const [lower, upper] = category;
  return `${formatStrRevenueFromNumber(lower)}-${formatStrRevenueFromNumber(upper)}`;
}
