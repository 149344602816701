import { Box, Button, Link, Menu, Stack, Typography } from '@mui/material';
import GoToIcon from 'icons/GoToIcon';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { appTheme } from 'theme';
import { formatNumber, formatRevenueFromData } from 'utils/finance-utils';

import TableRowLoading from './TableRowLoading';

export default function RenderRevenues({ value, color }) {
  const [showTooltip, setShowTooltip] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const data = value?.data;
  const isEstimated = data?.consensus?.is_estimated;
  const finalValue = useMemo(() => {
    return formatRevenueFromData(data);
  }, [data]);

  function parseSource(sourceData) {
    let sourceStr = null;
    if (sourceData) {
      const range_lower = sourceData.revenue_range_lower;
      const range_upper = sourceData.revenue_range_upper;
      if (range_lower && range_upper) {
        sourceStr = `$${formatNumber(range_lower)}-$${formatNumber(range_upper)}`;
      } else if (range_lower) {
        sourceStr = `$>${formatNumber(range_lower)}`;
      } else if (range_upper) {
        sourceStr = `$<${formatNumber(range_upper)}`;
      } else if (sourceData.clean_revenue_amount) {
        sourceStr = `$${formatNumber(sourceData.clean_revenue_amount)}`;
      }
    }
    return sourceStr;
  }

  const loading = value?.loading;
  if (loading) {
    return <TableRowLoading></TableRowLoading>;
  }
  if (!finalValue) return null;

  return (
    <Stack width="100%" padding={0}>
      <Button
        onClick={handleClick}
        fullWidth
        disableRipple
        sx={{
          '&:hover': { backgroundColor: 'unset' }
        }}>
        <Stack
          direction="row"
          alignItems="center"
          width="100%"
          onMouseEnter={() => setShowTooltip(!showTooltip)}
          onMouseLeave={() => setShowTooltip(false)}>
          <Typography variant="paragraph" color={color} noWrap display="block">
            {finalValue}
          </Typography>
          {data?.consensus && (
            <Box
              sx={{
                marginLeft: 'auto',
                backgroundColor: 'background.bg100',
                borderRadius: '2px',
                padding: '6px'
              }}>
              <Typography color="greyColors.grey300" variant="h4">
                {isEstimated ? 'E' : 'A'}
              </Typography>
            </Box>
          )}
        </Stack>
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock
        PaperProps={{
          style: { backgroundColor: appTheme.palette.secondary.secondary1 }
        }}
        MenuListProps={{
          style: { backgroundColor: appTheme.palette.secondary.secondary1 }
        }}>
        <Stack
          sx={{
            backgroundColor: 'secondary.secondary1',
            color: 'white',
            padding: '4px'
          }}>
          <Box
            sx={{
              backgroundColor: '#374B71',
              borderRadius: '2px',
              padding: '4px',
              width: 'fit-content'
            }}>
            <Typography color="white" variant="h4">
              {isEstimated ? 'E' : 'A'}
            </Typography>
          </Box>
          <Typography
            color="white"
            variant="paragraphSemiBold"
            paddingBottom="8px"
            paddingTop="4px">
            {isEstimated ? 'Estimated' : 'Actual'} Revenue
          </Typography>
          {data &&
            Object.values(data)
              .filter(
                (source) =>
                  source &&
                  source.source_name !== 'consensus' &&
                  source.is_estimated === isEstimated
              )
              .map((source) => (
                <Stack
                  key={source.source_name}
                  borderBottom="1px solid"
                  borderColor="greyColors.grey300">
                  <Typography variant="paragraphMedium" color="#A8B2C2" paddingTop="4px">
                    Source:
                  </Typography>
                  <Stack direction="row" gap="8px" padding="8px" alignItems="center">
                    {source.link_to_source && (
                      <Link
                        minHeight={0}
                        href={source.link_to_source}
                        underline="none"
                        target="_blank"
                        rel="noopener"
                        color="white"
                        padding="2px">
                        <GoToIcon />
                      </Link>
                    )}
                    <Typography variant="paragraphMedium">
                      {source.source_name
                        .split('_')
                        .map((word) => word[0].toUpperCase() + word.substring(1))
                        .join(' ')}
                    </Typography>
                    <Typography marginLeft="auto" variant="paragraphMedium">
                      {parseSource(source)}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
        </Stack>
      </Menu>
    </Stack>
  );
}

RenderRevenues.propTypes = {
  value: PropTypes.object,
  color: PropTypes.string,
  variant: PropTypes.string
};

RenderRevenues.defaultProps = {
  color: 'greyColors.grey300',
  variant: 'paragraph'
};
