import {
  addExtractedNames,
  deleteExtractedNames,
  upadteExtractedNamesLoading,
  upadteExtractedNamesTitle
} from 'reducer/llm-queries-slice';
import { getCompanyMetasMergedRowsMapByListId } from 'selectors/companyMetas';
import { getCompanyListNameByListId } from 'selectors/explore';
import {
  getExploreFilteredMetaIdsIfListFiltered,
  getExploreTableSelectedCompanies
} from 'selectors/explore-table-ui';
import { getValueByName, getValueFromExtraData } from 'utils/explore-columns-data-extractors';

function getColumnValuesFromColumn(column, listId) {
  return (dispatch, getState) => {
    const state = getState();
    const filteredMetaIdsIfFiltered = getExploreFilteredMetaIdsIfListFiltered(state, listId);
    const selectedCompanies = getExploreTableSelectedCompanies(state, listId);

    let selectedCompanyMetaIds = null;
    if (selectedCompanies?.length > 0) {
      selectedCompanyMetaIds = selectedCompanies.map((company) => company.companyMetaId);
    } else if (filteredMetaIdsIfFiltered) {
      selectedCompanyMetaIds = filteredMetaIdsIfFiltered;
    }

    const companyListMetas = getCompanyMetasMergedRowsMapByListId(state, listId);
    const columnValues = Object.values(companyListMetas)
      .filter(
        (row) => !selectedCompanyMetaIds || selectedCompanyMetaIds.includes(row.companyMetaId)
      )
      .map((row) => {
        let value = null;
        if (column.type?.includes('custom_analytics')) {
          value = getValueFromExtraData(row, column.field);
          if (!value) {
            value = row.customColumns?.[column.field];
            if (!value && value !== false) {
              value = '';
            }
          }
        } else if (column.type === 'basic_data_column') {
          value = getValueByName(row, column.field);
        } else {
          value = row.customColumns?.[column.field];
        }
        if (value?.answer || value?.answer === '') {
          value = value.answer;
        }

        return value;
      })
      .filter((value) => value);
    return columnValues;
  };
}

function getNamesToExclude(listId) {
  return (dispatch, getState) => {
    const state = getState();
    const companyListMetas = getCompanyMetasMergedRowsMapByListId(state, listId);
    const namesToExclude = Object.values(companyListMetas).map((row) => row.name);
    return namesToExclude;
  };
}

export function createListFromValuesAction(listId, title, values, namesToExclude, onError) {
  return (dispatch, getState, apiClient) => {
    const state = getState();
    const listName = getCompanyListNameByListId(state, listId);

    dispatch(upadteExtractedNamesLoading({ loading: true }));
    dispatch(upadteExtractedNamesTitle({ title: `${title} from ${listName}` }));

    apiClient.llm_queries
      .getIsColumnValuesContainNames(values, title)
      .then((response) => {
        if (response.ok && response.data) {
          if (response.data.answer) {
            apiClient.llm_queries
              .getNamesFromColumnValues(values, title, namesToExclude)
              .then((response2) => {
                if (response2.ok && response2.data) {
                  dispatch(addExtractedNames({ names: response2.data.names }));
                  dispatch(upadteExtractedNamesLoading({ loading: false }));
                } else {
                  onError?.('Failed to extract names');
                  dispatch(deleteExtractedNames());
                }
              })
              .catch(() => {
                onError?.('Failed to extract names');
                dispatch(deleteExtractedNames());
              });
          } else {
            onError?.('No names found in the provided data');
            dispatch(deleteExtractedNames());
          }
        } else {
          onError?.('Failed to extract names');
          dispatch(deleteExtractedNames());
        }
      })
      .catch(() => {
        onError?.('Failed to extract names');
        dispatch(deleteExtractedNames());
      });
  };
}

export function createListFromColumnAction(listId, currentColumn, onError) {
  return (dispatch) => {
    const columnName = currentColumn.headerName;
    const columnValues = dispatch(getColumnValuesFromColumn(currentColumn, listId));
    const namesToExclude = dispatch(getNamesToExclude(listId));
    dispatch(createListFromValuesAction(listId, columnName, columnValues, namesToExclude, onError));
  };
}

export function createListFromDomainKnowledgeAction(listId, resource, onError) {
  return (dispatch) => {
    const values = [resource.data?.message?.answer];
    const { title } = resource.data;

    dispatch(createListFromValuesAction(listId, title, values, [], onError));
  };
}
