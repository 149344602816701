import { renameSavedQuery } from 'reducer/saved-queries-slice';

import { loadSavedQueriesAction } from './load-saved-queries-action';

export function renameSavedQueryAction(savedQueryId, newName) {
  return (dispatch, _getState, apiClient) => {
    dispatch(renameSavedQuery({ savedQueryId, name: newName }));
    apiClient.saved_queries.renameSavedQuery(savedQueryId, { name: newName }).catch(() => {
      dispatch(loadSavedQueriesAction());
    });
  };
}
