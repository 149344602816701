import countriesJson from 'constants/countries.json';
import countryFlagEmoji from 'country-flag-emoji';

const { ref_country_codes: countries } = countriesJson;

export const countryObjFromCountry = (countryName) => {
  const countryObj = countries.find(
    ({ country, alias, alpha2, alpha3 }) =>
      countryName === country ||
      countryName === alias ||
      countryName === alpha2 ||
      countryName === alpha3
  );
  return countryObj;
};

export const countryCodeFromCountry = (countryName) => {
  const countryObj = countryObjFromCountry(countryName);
  return countryObj?.alpha2;
};

export const countryEmojiFromCountry = (countryName) => {
  const countryCode = countryCodeFromCountry(countryName);
  if (!countryCode) return null;
  const { emoji } = countryFlagEmoji.get(countryCode) || {};
  return emoji ? emoji : null;
};
