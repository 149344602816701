import BaseClient from './BaseClient';

const URL_PREFIX = 'pusher';

export default class PusherAuthClient extends BaseClient {
  auth(socket_id, channel_name) {
    return this.post(`${URL_PREFIX}/auth`, { socket_id, channel_name }, null);
  }

  sendNewMessage(channel_name, event_name, message) {
    return this.post(`${URL_PREFIX}/send_message_to_general`, {
      channel_name,
      event_name,
      message
    });
  }
}
