import { Avatar, Box, Chip, Stack, Typography } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import CustomMarkdown from 'components/CustomMarkdown';
import DotsAnimation from 'components/DotsAnimation';
import CustomAnalyticsAnswerComponent from 'components/elements/table/CustomAnalyticsAnswerComponent';
import { CUSTOM_ANALYTICS_PLACEMENT } from 'constants/custom-analytics';
import { TOAST_TYPES } from 'constants/toasts';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import ArrowIcon from 'icons/ArrowIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getThreadKey, updatePromptClipboard } from 'reducer/custom-analytics';
import { getUserIsDev } from 'selectors/user';
import { formatAnswerBody } from 'utils/custom-analytics-utils';
import { INITIAL_PROMPT_CONFIG } from 'utils/prompts-utils';

import RenderDiscoveryRunFeedMessage from './RenderDiscoveryRunFeedMessage';

function CustomAnalyticsFeedMessageComponent({
  listId,
  companyMetaId,
  title,
  companyLogo,
  body,
  followUpQuestions,
  sources,
  reasoning: reasoning_,
  perplexity,
  certainty,
  toolCallHistory,
  answerType,
  isLoading,
  responseType,
  placement,
  deepDiveId
}) {
  const { addToast } = useAppUtils();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState();
  const isDev = useOldStateSelector(getUserIsDev);

  let reasoning = reasoning_;

  if (isDev && perplexity && reasoning) {
    reasoning = `${reasoning} [perplexity ${perplexity}]`;
  }

  if (isDev && certainty && reasoning) {
    reasoning = `${reasoning} [certainty ${certainty}]`;
  }

  const handleExpandedChange = () => setExpanded(!expanded);

  const handleFollowupQuestion = (prompt) => {
    const threadKey = getThreadKey(listId, companyMetaId);

    dispatch(
      updatePromptClipboard({
        threadKey,
        promptConfig: { ...INITIAL_PROMPT_CONFIG, question: prompt }
      })
    );
    addToast('Question settings copied to current search', TOAST_TYPES.SUCCESS);
  };

  return title ? (
    <Stack direction="column" border="1px solid" borderColor="greyColors.grey50" borderRadius="4px">
      <Stack
        direction="row"
        alignItems="center"
        padding="4px 8px"
        borderBottom="1px solid"
        borderColor="greyColors.grey50"
        gap="4px"
        onClick={isLoading ? null : handleExpandedChange}
        sx={{
          cursor: isLoading ? 'default' : 'pointer',
          ':hover': { backgroundColor: isLoading ? 'unset' : 'background.bg75' }
        }}>
        <Stack direction="row" alignItems="center" gap="8px" padding="8px" width="100%">
          {isLoading && <DotsAnimation size="6px" gap="2.5px" />}
          {companyLogo && (
            <Avatar src={companyLogo} sx={{ width: 24, height: 24 }} variant="rounded" />
          )}
          <Typography
            variant="paragraphSemiBold"
            color={isLoading ? 'greyColors.grey100' : 'black'}>
            {title} {isLoading ? 'loading...' : ''}
          </Typography>
        </Stack>
        <Box sx={{ transform: expanded ? 'unset' : 'rotate(180deg)', marginLeft: 'auto' }}>
          <ArrowIcon />
        </Box>
      </Stack>
      {expanded && (
        <Stack direction="column" gap="16px" sx={{ padding: '8px 32px', overflowX: 'auto' }}>
          <Typography
            variant="paragraph"
            color="greyColors.grey300"
            textAlign="left"
            style={{ whiteSpace: 'pre-line' }}>
            <CustomMarkdown
              body={formatAnswerBody(body, answerType)}
              color="greyColors.grey300"
              listId={listId}
            />
          </Typography>
          <CustomAnalyticsAnswerComponent
            value={{ value: { sources, reasoning, toolCallHistory, placement } }}
            listId={listId}
            deepDiveId={deepDiveId}
          />
          {followUpQuestions?.length > 0 && (
            <Stack direction="column" gap="8px">
              <Typography textAlign="left" variant="paragraphMedium">
                Follow Up Questions
              </Typography>
              <Stack direction="row" flexWrap="wrap" alignItems="center" gap="8px">
                {followUpQuestions?.map((question, index) => (
                  <Chip
                    key={index}
                    label={question}
                    onClick={() => handleFollowupQuestion(question)}
                    sx={{ cursor: 'pointer' }}
                  />
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  ) : (
    <Stack direction="column" gap="16px" width="100%">
      {responseType === 'DISCOVERY' ? (
        <RenderDiscoveryRunFeedMessage searchId={body.searchId} listId={body.listId} />
      ) : (
        <Stack gap="16px" sx={{ overflowX: 'auto' }}>
          <Typography textAlign="left" variant="paragraph" style={{ whiteSpace: 'pre-line' }}>
            <CustomMarkdown
              body={formatAnswerBody(body, answerType)}
              color="greyColors.grey300"
              listId={listId}
              context={title}
            />
          </Typography>
          <CustomAnalyticsAnswerComponent
            listId={listId}
            deepDiveId={deepDiveId}
            value={{ value: { sources, reasoning, toolCallHistory, placement } }}
          />
        </Stack>
      )}
      {followUpQuestions && (
        <Stack direction="column" gap="8px" alignItems="flex-start" justifyContent="flex-start">
          <Stack
            direction="row"
            gap="4px"
            onClick={handleExpandedChange}
            sx={{ cursor: 'pointer' }}>
            {followUpQuestions?.length > 0 && (
              <React.Fragment>
                <Box sx={{ transform: expanded ? 'rotate(180deg)' : 'rotate(90deg)' }}>
                  <ArrowIcon />
                </Box>
                <Typography textAlign="left" variant="paragraphMedium">
                  Follow Up Questions
                </Typography>
              </React.Fragment>
            )}
          </Stack>
          {expanded && (
            <Stack direction="row" width="100%" flexWrap="wrap" alignItems="center" gap="8px">
              {followUpQuestions?.map((question, index) => (
                <Chip
                  key={index}
                  label={question}
                  onClick={() => handleFollowupQuestion(question)}
                  sx={{ cursor: 'pointer' }}
                />
              ))}
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
}

CustomAnalyticsFeedMessageComponent.propTypes = {
  listId: PropTypes.number,
  title: PropTypes.string,
  body: PropTypes.string,
  followUpQuestions: PropTypes.array,
  sources: PropTypes.object,
  reasoning: PropTypes.object,
  perplexity: PropTypes.number,
  certainty: PropTypes.arrayOf(PropTypes.number),
  isLoading: PropTypes.bool,
  companyLogo: PropTypes.string,
  toolCallHistory: PropTypes.object,
  answerType: PropTypes.string,
  responseType: PropTypes.string,
  companyMetaId: PropTypes.number,
  placement: PropTypes.oneOf(Object.values(CUSTOM_ANALYTICS_PLACEMENT)),
  deepDiveId: PropTypes.number
};

export default CustomAnalyticsFeedMessageComponent;
