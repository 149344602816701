import React from 'react';

export default function OutlinedInfoIcon() {
  const color = 'currentColor';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <circle cx="12" cy="12" r="8.57143" stroke={color} strokeWidth="0.857143" />
      <rect
        x="12.8125"
        y="16.9995"
        width="1.62506"
        height="6.50025"
        rx="0.812532"
        transform="rotate(-180 12.8125 16.9995)"
        fill={color}
      />
      <circle cx="12" cy="8.01558" r="1.01566" transform="rotate(-180 12 8.01558)" fill={color} />
    </svg>
  );
}
