import { Stack, Typography } from '@mui/material';
import CustomMarkdown from 'components/CustomMarkdown';
import PromptDisplayText from 'components/discovery/PromptDisplayText';
import PropTypes from 'prop-types';
import React from 'react';
import { formatAnswerBody } from 'utils/custom-analytics-utils';

import CustomAnalyticsSourceItemComponent from '../CustomAnalyticsSourceItemComponent';
import QuickViewBox from './QuickViewBox';
import QuickViewDivider from './QuickViewDivider';
import QuickViewExpandable from './QuickViewExpandable';

function QuickViewCA({ value }) {
  const { columnName, value: data, columnInfo, listId } = value;
  if (!value) return null;
  const { answer, sources, reasoning } = data;
  if (!answer) return null;
  const sourcesExist = sources && Object.values(sources).length > 0;
  return (
    <QuickViewBox title={columnName} tag="Custom analytics column" gap="16px">
      <PromptDisplayText
        prompt={{ ...columnInfo, forEachCompany: true }}
        answerType={columnInfo.answer_type}
        listId={listId}
        direction="column"
      />
      <CustomMarkdown
        body={formatAnswerBody(answer, columnInfo.answer_type)}
        listId={listId}
        context={`${columnName}`}
      />
      <QuickViewDivider />
      <QuickViewExpandable
        title={sourcesExist ? 'Sources' : 'No sources found'}
        disabled={!sourcesExist}>
        <Stack gap="8px">
          {Object.values(sources)?.map((source) => (
            <CustomAnalyticsSourceItemComponent key={source} listId={listId} source={source} />
          ))}
        </Stack>
      </QuickViewExpandable>
      <QuickViewDivider />
      <QuickViewExpandable
        title={reasoning ? 'Reasoning' : 'No reasoning found'}
        disabled={!reasoning}>
        <Typography variant="paragraph">{reasoning}</Typography>
      </QuickViewExpandable>
    </QuickViewBox>
  );
}

QuickViewCA.propTypes = { value: PropTypes.object };

export default QuickViewCA;
