/* eslint-disable require-unicode-regexp */
import { Box, Tooltip } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { generateNameFromQuery } from 'actions/llm_queries/generate-name-for-column-action';
import { addSavedQueryAction } from 'actions/saved_queries/add-saved-query-action';
import IconButtonComponent from 'components/IconButtonComponent';
import BookmarkIcon from 'icons/BookmarkIcon';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getListCustomColumnsByListId } from 'selectors/explore';
import { getAllSavedQueries } from 'selectors/saved-queries';
import { isEmpty } from 'utils/objects-utils';

import SetQueryNameDialog from './SetQueryNameDialog';

const SaveQueryDialog = forwardRef(
  ({ prompt, isSaved, setIsSaved, existColumnName, onSaveQuery, listId }, ref) => {
    const dispatch = useDispatch();
    const { addToast } = useAppUtils();

    const [columnName, setColumnName] = useState('');
    const [open, setOpen] = useState(false);
    const [genratingName, setGenratingName] = useState(false);

    const savedQueries = useSelector(getAllSavedQueries);
    const customColumns = useSelector((state) => getListCustomColumnsByListId(state, listId));

    const isDisabled = useMemo(() => {
      if (isEmpty(customColumns) || isEmpty(prompt?.columns)) {
        return false;
      }
      const customColumnIds = Object.keys(customColumns);
      if (prompt?.columns?.some((column) => customColumnIds.includes(column))) {
        return true;
      }
    }, [customColumns, prompt]);

    useEffect(() => {
      if (existColumnName) {
        setColumnName(existColumnName.replace(/\s*\(.*?\)$/, ''));
      }
    }, [existColumnName]);

    const closeDialog = () => {
      setOpen(false);
      setColumnName(existColumnName?.replace(/\s*\(.*?\)$/, '') || '');
    };

    useEffect(() => {
      if (prompt?.saved_query_id) {
        setIsSaved(Boolean(savedQueries?.[prompt?.saved_query_id]));
      }
    }, [prompt?.saved_query_id, savedQueries, setIsSaved]);

    const handleSaveQuery = () => {
      if (isSaved) return;
      const promptToSave = { ...prompt };
      promptToSave.company_meta_ids = null;
      delete promptToSave?.total_num_companies;
      setIsSaved(true);
      dispatch(
        addSavedQueryAction({
          name: columnName,
          parameters: promptToSave,
          query_type: 'COLUMN',
          description: null,
          addToast,
          onSaveQuery
        })
      );
      closeDialog();
    };

    const onOpenDialog = async () => {
      if (isDisabled || isSaved) return;
      setOpen(true);

      if (!columnName) {
        setGenratingName(true);
        const generatedName = await dispatch(generateNameFromQuery(prompt.question));
        if (generatedName?.data?.answer) {
          setColumnName(generatedName?.data?.answer);
        }
        setGenratingName(false);
      }
    };

    useImperativeHandle(ref, () => ({
      notifyFromParent() {
        onOpenDialog();
      }
    }));

    return (
      <div>
        <Tooltip title={isDisabled ? 'Saving query that uses added columns in not supported' : ''}>
          <Box>
            <IconButtonComponent
              id="save-query-button"
              disabled={isDisabled}
              onClick={onOpenDialog}
              sx={{ padding: 0, cursor: isSaved ? 'auto' : 'pointer' }}
              tag={isSaved ? 'Saved in Column Library' : 'Save query to library'}
              extraProps={{ 'aria-pressed': isSaved }}>
              <BookmarkIcon isBookmarked={isSaved} disabled={isDisabled} />
            </IconButtonComponent>
          </Box>
        </Tooltip>
        <SetQueryNameDialog
          title="Save to Column Library"
          open={open}
          onClose={closeDialog}
          columnName={columnName}
          setColumnName={setColumnName}
          onSaveQuery={handleSaveQuery}
          isDisabled={genratingName}
        />
      </div>
    );
  }
);

SaveQueryDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  listId: PropTypes.number,
  prompt: PropTypes.object,
  isSaved: PropTypes.bool,
  setIsSaved: PropTypes.func,
  existColumnName: PropTypes.string,
  onSaveQuery: PropTypes.func
};
SaveQueryDialog.displayName = 'SaveQueryDialog';

export default SaveQueryDialog;
